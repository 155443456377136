/*
    Week Day Selector
 */
.week-day-selector {
    display: inline-block;
    margin-bottom: 0;

    // Elements
    .wrapper {
        position: relative;

        padding-right: 0.88em;

        color: $color-primary;

        text-decoration: underline;
        text-decoration-skip-ink: auto;


        // Elements
        .icon {
            position: absolute;
            top: 57.5%;
            right: -0.25em;

            width: 1.38em;
            height: 1.38em;

            transform: translateY(-50%);
        }
    }

    .select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        color: transparent;
        font-size: 0;

        background-color: transparent;
        border: 0;
        opacity: 0.5;

        appearance: none;

        @media (-webkit-min-device-pixel-ratio: 0) {
            font-size: 16px; // IOS specific: Min 16px font-size to prevent zoom
        }


        // Types
        &::-ms-expand {
            display:none; // or visibility: hidden; to keep it's space/hitbox
        }


        // Elements
        option {
            color: $color-primary;
            font-size: rem(30);

            background-color: $color-light;
        }
    }

    &-heading .title {
        @media (--to-tiny-screen-w) {
            font-size: rem(22)
        }

        @media (--to-small-screen-w) {
            font-size: rem(30)
        }

        .wrapper {
            @media (--to-small-screen-w) {
                padding-right: 0;
            }
        }

        .icon {
            @media (--to-small-screen-w) {
                position: relative;
                top: rem(10);
                left: rem(-5);

                display: inline-block;

                transform: none;
            }
        }
    }
}
