/*
    Loader continer
 */
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(300);

    @media (--from-small-screen-w) {
        height: rem(400);
    }


    // States
    &.pad {
        padding-top: rem(80);

        @media (--from-small-screen-w) {
            padding-top: rem(100);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(145);
        }
    }
}
