/*
    Members portal passholders
*/
.members-portal-passholders {
    margin-bottom: rem(20);

    // Pass element contains serveral passholders
    .pass-info {
        margin-bottom: rem(20);

        // Pass Number
        h4 {
            background-color: $color-light-shade-2;
            padding: rem(10);
            margin-bottom: 0;
            font-weight: $weight-semibold;
        }

        // Passholder list
        table {
            display: block;
            width: 100%;
            margin: 0;

            @media (--from-small-screen-w) {
                display: table;
                border-collapse: collapse;
            }

            // Desktop version header, hidden in mobile screen
            thead {
                display: none;
                width: 100%;

                @media (--from-small-screen-w) {
                    display: table-header-group;;
                }
            }

            th {
                text-align: left;
                color: $color-light-shade-5;
                padding: rem(10);
            }

            // Name header should a bit right to align the name (there is a icon ahead)
            th:first-child {
                padding-left: rem(50);
            }

            tbody {
                display: block;
                width: 100%;

                @media (--from-small-screen-w) {
                    display: table-row-group;
                }
            }

            tr {
                display: block;
                width: 100%;
                margin-bottom: rem(10);
                border: 1px solid $color-light-shade-2;

                @media (--from-small-screen-w) {
                    display: table-row;
                }
            }

            td {
                display: flex;
                text-align: left;
                padding: rem(10);
                border-bottom: 1px solid $color-light-shade-2;

                @media (--from-small-screen-w) {
                    display: table-cell;
                }

                // Mobile version header, hidden in desktop screen
                .mobile-table-header {
                    display: block;
                    padding: 0 0 0 rem(10);
                    color: $color-light-shade-5;
    
                    @media (--from-small-screen-w) {
                        display: none;
                    }
                }
            }

            // Scan pass button
            button {
                width: 100%;
                margin: 0 rem(10);

                @media (--from-small-screen-w) {
                    width: auto;
                }
            }

            // Mobile header with content
            .table-body-content {
                flex: 1;
                display: flex;
                align-items: center;

                p {
                    margin-bottom: 0;
                }
            }

            // Passholder name
            .content-name {
                display: inline-block;
                font-weight: $weight-semibold;
                margin-left: rem(10);
            }

            // Passholder photo
            .image {
                border-radius: 50%;
                width: rem(30);
                height: rem(30);
            }
        }
    }

    // Scan pass modal content
    .pass-scan-modal-content {
        @extend .modal-content;
        padding: 0;
        position: relative;
        // change with pass-scan-slider-btn positioning
        width: 80%;

        @media(--to-small-screen-w) {
            margin-top: rem(20);

        }
        
        // close modal button for mobile
        .pass-scan-modal-close-mobile {
            @media(--from-small-screen-w) {
                display: none;
            }

            position: absolute;
            bottom: rem(10);
            width: 100%;
            text-align: center;
            
            .btn-close {
                font-size: rem(11);
                text-decoration: underline;
            }
        }

        // close modal button for desktop
        .pass-scan-modal-close-desktop {
            @media(--to-small-screen-w) {
                display: none;
            }

            position: absolute;
            top: rem(10);
            right: rem(10);
            
            .btn-close {
                display: block;
                margin: 0 auto;
                .icon {
                    color: $color-primary;
                    height: rem(25);
                    width: rem(25);
                }
                span {
                    display: block;
                    font-size: rem(11);
                    text-align: center;
                }
            }
        }

        .pass-scan-slider-btn {
            position: absolute;
            top: 50%;

            background-color: $color-dark-tint-3;
            color: white;

            &.next {
                // vertically center and rotate
                transform: translateY(-50%) rotate(-90deg);
                // change with pass-scan-modal-content width
                right: rem(-20);
            }
            
            &.prev {
                // vertically center and rotate
                transform: translateY(-50%) rotate(90deg);
                // change with pass-scan-modal-content width
                left: rem(-20);
            }
        }
    }

    .pass-scan-card {
        @media(--from-small-screen-w) {
            display: flex;
            > div {
                width: 50%;
            }
        }

        // Center and padding
        > div {
            padding: rem(50) 0;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .pass-scan-card-details {
            background-color: $color-primary;

            .pass-scan-card-avatar {
                // photo
                img {
                    height: rem(200);
                }
                
                // default avatar icon
                .icon {
                    color: $color-light-shade-3;
                    // remove inline gaps
                    display: block;
                }
            }

            .pass-scan-card-text {
                font-weight: $weight-semibold;
                text-align: center;

                &-name {
                    font-size: rem(15);
                    margin-bottom: 0;
                    margin-top: rem(10);
                }
                &-role {
                    font-size: rem(13);
                    margin-bottom: 0;
                    margin-top: rem(5);
                }
            }
        }

        .pass-scan-card-qrcode {
            background-color: $color-white;
        }
    }

    // Upload image modal
    .pass-upload-image-modal-content {
        margin: rem(30);

        h4 {
            background-color: $color-light;
            margin-bottom: rem(20);
            padding-left: 0;
        }

        p {
            margin: 0;
        }

        ul {
            margin-left: rem(20);
        }

        button {
            margin-right: rem(10);
        }

        .import-message {
            color: $color-import;
            font-weight: $weight-semibold;
            margin: rem(30) 0;
        }

        .dropzone {
            padding: rem(30);
            margin: rem(20) 0;
            border: 1px dashed $color-light-shade-4;
            background-color: $color-light-shade-2;

            p {
                color: $color-light-shade-4;
            }
        }

        .image-container {
            width: rem(180);
            margin-bottom: rem(30);

            img {
                width: 100%;
                height: 100%;
            }
        }

        .cropper-container {
            position: relative;
            width: rem(300);
            height: rem(300);
        }

        .cropper-controller {
            margin: rem(20) 0;
        }

        .error-message {
            margin-bottom: rem(20);
            color: $color-error;
        }
    }
}