/*
    Input
 */
.input,
.input[type='number'],
.input[type='tel'] {
    width: 100%;
    height: rem(44);
    padding-top: 0;
    padding-right: rem(8);
    padding-bottom: 0;
    padding-left: rem(8);

    color: inherit;
    font-family: inherit;
    font-size: rem(18);
    line-height: rem(42);

    background-color: $color-light;
    border: rem(1) solid $color-light-shade-3;
    border-radius: rem(3);

    appearance: none;


    // States
    &.is-invalid {
        border: rem(1) solid $color-error;
    }

    &:read-only {
        cursor: initial;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    margin: 0;

    -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input:focus,
input[type="checkbox"]:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
