/*
    Pagebreak Quote Block
 */
.pagebreak-quote-block {

    // Elements
    &-inner {
        position: relative;

        display: flex;
        align-items: center;
        min-height: rem(200);
        padding-top: rem(20);
        padding-bottom: rem(20);

        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media (--from-medium-screen-w) {
            min-height: rem(320);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(60);
            padding-bottom: rem(60);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 100%;

            background-color: $color-dark-opac-1;

            content: '';
        }
    }

    &-inner.dark {
        &::before {
            background-color: $color-dark;
        }
    }

    &-quote {
        position: relative;

        padding-right: rem(20);
        padding-left: rem(50);

        @media (--from-small-screen-w) {
            padding-left: rem(80);
        }

        @media (--from-medium-screen-w) {
            padding-left: rem(100);
        }

        @media (--from-normal-screen-w) {
            padding-left: rem(135);
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: rem(8);
            height: 100%;

            background-color: $color-primary;

            @media (--from-medium-screen-w) {
                content: '';
            }
        }
    }

    blockquote {
        position: relative;

        width: 100%;
        max-width: rem(440);
        margin-bottom: rem(10);

        @media (--from-medium-screen-w) {
            max-width: rem(600);
        }


        // Elements
        &::before {
            position: absolute;
            top: 0;
            left: rem(-10);

            transform: translateX(-100%);

            @media (--from-normal-screen-w) {
                top: rem(-5);

                font-size: rem(60);
            }
        }

        p {
            margin-bottom: 0;

            color: $color-light;

            @media (--from-small-screen-w) {
                font-size: rem(26);
            }

            @media (--from-normal-screen-w) {
                font-size: rem(36);
            }
        }
    }

    cite {
        margin-bottom: 0;

        color: $color-light;

        @media (--to-medium-screen-w) {
            font-size: rem(14);
        }
    }
}
