/*
    Pass Selector
*/
.pass-selector {
    display: flex;
    flex-direction: column;

    min-height: rem(350);


    // Elements
    &-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (--from-small-screen-w) {
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }

    &-item {
        position: relative;

        display: flex;
        align-items: center;
        flex-direction: column;
        width: calc(50% - rem(5));
        max-width: rem(170);
        padding-bottom: rem(45);
        margin-bottom: rem(8);

        cursor: pointer;

        transition: background-color 0.3s ease-in-out;

        @media (--from-small-screen-w) {
            width: 100%;
            max-width: rem(170);
        }


        // States
        &.is-selected {
            background-color: $color-primary;
            border: rem(4) solid transparent;
            border-bottom: none;

            .pass-selector-item-description {
                color: $color-light;
            }

            .pass-selector-item-image {
                opacity: 1;
            }
        }

        &:hover {
            .pass-selector-item-image {
                opacity: 1;
            }
        }


        // Elements
        &:not(:last-of-type) {
            @media (--from-small-screen-w) {
                margin-right: rem(10);
            }
        }

        &-image {
            opacity: 0.6;

            transition: opacity 0.3s ease-in-out;
        }

        &-input {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;

            opacity: 0;
        }

        &-description {
            position: absolute;
            bottom: rem(10);
            left: 0;

            width: 100%;

            font-size: rem(18);
            text-align: center;

            transition: color 0.15s ease-in-out;
        }
    }
}
