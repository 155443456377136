/*
    Navigation Children
 */
.navigation-children {
    flex-basis: 100%;

    @media (--to-normal-screen-w) {
        display: none;
        padding-bottom: rem(10);
    }

    @media (--from-normal-screen-w) {
        position: absolute;
        top: rem(70);
        left: 50%;

        display: none;
        width: rem(260);
        margin-top: rem(-10);

        cursor: pointer;
        background-color: $color-dark-opac-2;
        box-shadow: 0 2px 10px $color-dark-shade-1-opac-3;

        transform: translateX(-50%);
    }


    // Elements
    &::before {
        position: absolute;
        top: rem(-8);
        left: calc(50% - rem(8) / 2);

        display: inline-block;

        border-right: rem(8) solid transparent;
        border-bottom: rem(8) solid $color-dark-opac-2;
        border-left: rem(8) solid transparent;

        @media (--from-normal-screen-w) {
            content: '';
        }
    }

    .sub-item {
        transition: color 0.2s, background-color 0.2s;

        @media (--to-normal-screen-w) {
            margin-right: rem(25);
            margin-left: rem(45);
        }

        @media (--from-normal-screen-w) {
            margin-right: rem(20);
            margin-left: rem(20);
        }


        // States
        &:hover,
        &.is-active {
            opacity: 0.5;
        }


        // Types
        &:not(:last-child) {
            @media (--from-normal-screen-w) {
                border-bottom: 1px solid $color-light;
            }
        }

        &:not(.button) {
            display: block;
            padding-top: rem(13);
            padding-bottom: rem(13);

            color: $color-light;
            text-decoration: none;
        }

        &.button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: rem(50);
            margin-top: rem(15);
            margin-right: rem(35);
            margin-bottom: rem(15);
            margin-left: rem(45);

            color: $color-light;

            @media (--to-normal-screen-w) {
                background-color: transparent;

                border: 1px solid $color-primary;
            }

            @media (--from-normal-screen-w) {
                height: rem(40);
                margin: rem(15) rem(30);
            }


            // States
            &:hover {
                @media (--to-normal-screen-w) {
                    background-color: transparent;

                    transition: none;
                }
            }
        }
    }
}
