/*
    Dual tab block
*/
.dual-tab-block {
    margin-bottom: rem($block-spacing);

    .inner {
        box-shadow: $default-box-shadow;
    }

    .tab-titles {
        display: flex;

        > .title {
            width: 50%;
            padding: rem(30) rem(20);
            margin: 0;

            color: $color-black;
            text-align: center;
            
            cursor: pointer;
            background-color: $color-white;
            outline: none;

            &:hover {
                color: $color-primary;
            }

            &.is-selected {
                color: $color-primary;

                background-color: $color-armadillo;
                border-left: rem(8) solid $color-primary;
            }

            h2 {
                margin-bottom: 0;

                font-size: rem(22);
                white-space: pre-wrap;

                @media(--from-medium-screen-w) {
                    font-size: rem(36);
                }
            }
        }
    }

    .tab-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: rem(30) rem(20);

        color: $color-white;

        background-color: $color-armadillo;

        @media(--from-medium-screen-w) {
            padding: rem(80);
        }

        @media(--from-large-screen-w) {
            flex-direction: row;
            align-items: flex-start;
        }

        & > *:not(:last-child) {
            @media(--from-large-screen-w) {
                margin-right: rem(20);
            }
        }

        .content {
            width: 100%;
            
            @media(--from-large-screen-w) {
                flex: 1;
            }

            > * {
                margin-bottom: rem(20);
            }
        }

        .image {
            width: 100%;
            max-width: rem(590);
            max-height: rem(520);
            object-fit: contain;

            @media(--from-large-screen-w) {
                width: 50%;
            }
        }
    }
}
