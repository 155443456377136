/*
    Pass Details
*/
.pass-details {
    &-type {
        margin-bottom: rem(20);

        &-inner {
            background-color: $color-light;
            box-shadow: $default-box-shadow;
        }

        &-header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: rem(80);
            padding: rem(10) rem(20);

            background-color: $color-dark;

            @media (--from-small-screen-w) {
                padding: 0 rem(20);
            }

            @media (--from-normal-screen-w ) {
                padding: 0 rem(65);
            }

            .title,
            .subtitle {
                color: $color-light;
                font-size: rem(18);
            }

            .subtitle {
                font-weight: $weight-bold;
            }
        }

        &-pass-id {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: rem(10) rem(20);

            @media (--from-ipad-screen-w) {
                flex-wrap: nowrap;
            }

            @media (--from-normal-screen-w ) {
                padding: rem(20) rem(65);
            }

            &-fields {
                @media (--to-ipad-screen-w) {
                    width: 100%;
                }

                @media (--from-ipad-screen-w) {
                    min-width: rem(200);
                    margin-right: rem(25);
                }

                @media (--from-normal-screen-w) {
                    width: rem(400);
                    margin-right: rem(40);
                }
            }

            &-aside {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                max-width: rem(710);

                @media (--from-ipad-screen-w) {
                    flex-wrap: nowrap
                }

                &-image {
                    flex-basis: rem(100);
                    max-width: rem(200);
                    margin-top: rem(10);
                    margin-right: rem(20);
                    margin-bottom: rem(10);

                    border: rem(1) solid $color-dark;
                }

                &-description {
                    flex-grow: 1;
                    margin-bottom: rem(5);
                }
            }
        }

        &-form {
            &-header {
                display: flex;
                align-items: center;
                flex-basis: 100%;
                min-height: rem(55);
                padding: rem(10) rem(20);

                background-color: $color-primary;

                @media(--from-small-screen-w) {
                    padding: 0 rem(20);

                    background-color: $color-dark-tint-3;
                }

                @media (--from-normal-screen-w ) {
                    padding: 0 rem(65);
                }

                &-labels {
                    flex-grow: 1;
                }

                &-button[type='button'].primary {
                    @media (--to-small-screen-w ) {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }

                .title,
                .info {
                    color: $color-light;
                    font-size: rem(18);

                    @media (--from-small-screen-w ) {
                        display: inline-block;
                    }
                }

                .title {
                    margin: 0;

                    font-weight: $weight-bold;
                }

                .info {
                    @media (--from-small-screen-w ) {
                        padding-left: rem(20);
                    }
                }
            }

            &-inner {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: rem(30) rem(20);

                @media (--from-ipad-screen-w) {
                    flex-wrap: nowrap;
                }

                @media (--from-normal-screen-w ) {
                    padding: rem(40) rem(65);
                }
            }

            &-fields {
                @media (--to-ipad-screen-w) {
                    width: 100%;
                }

                @media (--from-ipad-screen-w) {
                    min-width: rem(200);
                    margin-right: rem(25);
                }

                @media (--from-normal-screen-w) {
                    width: rem(400);
                    margin-right: rem(40);
                }
            }
        }

        &-more-children {
            padding-right: rem(20);
            padding-bottom: rem(55);
            padding-left: rem(20);

            text-align: center;
        }

        &-more-children-label {
            padding-bottom: rem(30);

            font-size: rem(18);
            font-weight: $weight-bold;
        }
    }

    &-footer {
        text-align: center;

        &-terms {
            margin-bottom: rem(30);

            font-size: rem(18);
        }

        &-button {
            margin-bottom: rem(20);
        }
    }
}
