/*
    Elements on the thank you page
 */
.thank-you {

    // Elements
    .message {
        svg {
            display: block;
            width: rem(35);
            height:rem(35);
            margin-right: auto;
            margin-bottom: rem(20);
            margin-left: auto;

            color: $color-light;

            background-color: $color-success;
            border: rem(6) solid $color-success;
            border-radius: 50%;
        }

        &-body {
            margin-top: rem(20);
            margin-bottom: rem(10);

            font-size: rem(18);
            text-align: center;

            @media (--from-small-screen-w) {
                margin-bottom: rem(30);
            }
        }
    }

    .order-number {
        margin-bottom: rem(20);

        text-align: center;

        @media (--from-small-screen-w) {
            font-size: rem(18);
        }
    }

    .notice {
        display: block;
        padding-top: rem(30);
        padding-right: rem(35);
        padding-bottom: rem(30);
        padding-left: rem(35);

        font-size: rem(18);
        text-align: center;
    }

    .to-homepage-wrapper {
        margin-top: rem(50);
        margin-bottom: rem(30);

        text-align: center;
    }

    .next-steps {
        .paragraph {
            margin-bottom: rem(30);
            
            p {
                margin-bottom: rem(8);
            }
        }
    }
}
