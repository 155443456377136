/*
    Page View
 */
 .page-view {
    &.no-banner {
        margin-top: calc(rem($header-height-small) + rem(30));

        @media (--from-normal-screen-w) {
            margin-top: calc(rem($header-height-large) + rem(60));
        }
    }
}
