/*
    Knockout Text
 */
svg.knockout-text {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;


    // SVG Specific
    text {
        text-anchor: middle;
    }


    // Elements
    .background-colour {
        fill: $color-light-shade-2;

        // This technique must be sued without quotes to reference the mask by id (#knockout-text-mask)
        mask: url(#knockout-text-mask); // stylelint-disable-line function-url-quotes
    }

    .text {
        font-family: $font-tertiary;
        font-size: rem(160);
        font-weight: $weight-bold;

        @media (--from-tiny-screen-w) {
            font-size: rem(260);
        }

        @media (--from-small-screen-w) {
            font-size: rem(360);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(400);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(500);
        }
    }

    .alpha {
        fill: $color-light;
    }
}
