/*
    Media
 */
picture,
img,
svg {
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 100%;
}

img {
    height: auto;
}

picture,
img {
    display: block;
}

iframe {
    width: 100%;
}

use {
    width: 100%;
    height: 100%;
}
