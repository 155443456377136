/*
    Tile Grid
 */
$--image-height-small: 240;

.tile-grid {
    position: relative;

    margin-right: rem(-$block-spacing-half);
    margin-left: rem(-$block-spacing-half);

    @media (--from-medium-screen-w) {
        display: flex;
        flex-wrap: wrap;
    }


    // Types
    &.is-empty {
        padding-bottom: rem(50); // Add height for loading screen
    }


    // Elements
    & > * {
        padding-right: rem($block-spacing-half);
        padding-left: rem($block-spacing-half);
        margin-bottom: rem($block-spacing);

        @media (--from-medium-screen-w) {
            display: flex;
            flex-basis: calc(100% / 2);
            flex-direction: column;
            flex-shrink: 0;
            max-width: calc(100% / 2);
        }

        @media (--from-large-screen-w) {
            flex-basis: calc(100% / 3);
            flex-wrap: nowrap;
            max-width: calc(100% / 3);
        }
    }


    // Featured & Extended
    .featured-article-tile,
    .extended-article-tile {
        .meta br {
            @media (--from-tiny-screen-w) {
                display: none;
            }
        }

        .author {
            @media (--to-tiny-screen-w) {
                margin-left: 0;
            }
        }
    }

    // Featured
    .featured-article-tile {
        flex-basis: calc(100%);
        max-width: 100%;

        @media (--from-normal-screen-w) {
            flex-basis: calc(100% / 3 * 2);
            max-width: calc(100% / 3 * 2);
        }


        // Elements
        .tile-thumbnail {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: rem($--image-height-small);

            &::before {
                background: linear-gradient(to bottom, color($color-dark alpha(0%)) 0%, $color-dark 100%);
            }

            @media (--from-small-screen-w) {
                // Only for small screens
                display: none;
            }
        }

        .tile-inner {
            height: rem(400);

            @media (--to-small-screen-w) {
                // Important to override inline style declaration
                background-image: none !important;
            }

            @media (--from-normal-screen-w) {
                height: rem(530);
            }
        }

        .heading-group {

            .title {
                @media (--to-small-screen-w) {
                    overflow: hidden;

                    text-overflow: ellipsis;
                }

                @media (--from-small-screen-w) {
                    font-size: rem(36);
                }
            }

            .flag {
                height: rem(52);

                @media (--to-normal-screen-w) {
                    bottom: auto;

                    width: rem(30);
                    height: rem(40);
                    margin-top: rem(15); // Adjust position without effecting absolute
                }
            }
        }

        .subtitle {
            margin-bottom: rem(10);

            @media (--from-normal-screen-w) {
                font-size: rem(18);
            }
        }
    }

    // Extended && Column Call To Action components
    .extended-article-tile {

        // Elements
        &-inner {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            background-color: $color-light;
        }

        .tile-image-header {
            flex-grow: 0;
        }

        .subtitle {
            margin-bottom: rem(20);

            @media (--from-small-screen-w) and (--to-medium-screen-w) {
                font-size: rem(14);
            }

            @media (--from-small-screen-w) {
                font-size: rem(18);
            }
        }

        .detail {
            font-weight: $weight-bold;
        }

        .tile-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            padding-top: rem(15);
            padding-right: rem(24);
            padding-bottom: rem(15);
            padding-left: rem(20);

            box-shadow: $default-box-shadow;

            @media (--from-small-screen-w) {
                padding-left: rem(30);
            }

            @media (--to-medium-screen-w) {
                padding-right: rem(40);
            }
        }

        .tile-image-header {
            @media (--to-medium-screen-w) {
                overflow: visible;
            }
        }

        .heading-group {
            .flag {
                @media (--to-medium-screen-w) {
                    top: 100%;

                    width: rem(30);
                    height: rem(40);
                }
            }
        }
    }

    // Other Elements
    .image-overlay::before {
        height: 60%;
    }

    .tile-inner,
    .tile-image-header {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
        height: rem($--image-height-small);
        padding-top: rem(20);
        overflow: hidden;

        color: $color-light;
        text-decoration: none;

        background-color: $color-dark;
        box-shadow: $default-box-shadow;


        // States
        &:hover,
        &:focus {
            cursor: pointer;


            // Elements
            &::after {
                @media (--from-medium-screen-w) {
                    opacity: 1;
                }
            }

            .heading-group {
                @media (--from-medium-screen-w) {
                    transform: translateX(rem(40));
                }
            }

            .author {
                @media (--from-medium-screen-w) {
                    transform: translateX(rem(-40));
                }
            }
        }


        // Elements
        &::after {
            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 100%;
            height: 100%;

            background-color: $color-overlay;
            opacity: 0;

            transition: 0.2s opacity ease-in-out;

            @media (--from-medium-screen-w) {
                content: '';
            }
        }
    }

    .author,
    .heading-group {
        position: relative;

        transition: 0.2s transform ease-in-out;
    }

    .author {
        @media (--to-medium-screen-w) {
            margin-left: rem(20);
        }
    }

    .heading-group {
        z-index: $layer-tile-block-heading-group;

        width: 100%;
        padding-top: rem(4);
        padding-right: rem(55);
        padding-bottom: rem(15);
        padding-left: rem(20);

        text-shadow: $text-shadow;

        @media (--from-small-screen-w) {
            padding-bottom: rem(20);
            padding-left: rem(30);
        }

        @media (--from-medium-screen-w) {
            padding-right: rem(30);
            padding-bottom: rem(4);
            margin-bottom: rem(20);
        }


        // Elements
        .flag {
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(48);
            height: rem(48);

            color: $color-light;

            background-color: $color-primary;

            @media (--to-medium-screen-w) {
                right: 0;
                bottom: 0;
            }

            @media (--from-small-screen-w) {
                height: rem(84);
            }

            @media (--from-medium-screen-w) {
                top: 0;
                left: rem(-44);

                height: 100%;
            }


            // Elements
            .icon {
                position: relative;

                display: block;
                width: rem(40);
                height: rem(40);
                max-width: none;

                transform: rotate(-90deg);

                @media (--from-medium-screen-w) {
                    width: rem(45);
                    height: rem(45);
                }
            }
        }

        .title {
            margin-bottom: rem(10);

            font-weight: $weight-normal;
        }
    }

    .subtitle,
    .meta {
        margin-bottom: 0;

        font-size: rem(16);
    }

    .meta {
        position: relative;

        font-size: rem(14);

        @media (--from-tiny-screen-w) {
            font-size: rem(16);
        }

        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;
        }

        br {
            @media (--from-medium-screen-w) {
                display: none;
            }
        }
    }

    .tile-grid-sidebar {
        flex-basis: 100%;
        max-width: 100%;

        @media (--from-normal-screen-w) {
            display: flex;
            flex-basis: calc(100% / 3);
            flex-direction: column;
        }
    }
}
