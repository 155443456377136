.ticket-combo {
    @media (--from-normal-screen-w) {
        display: flex;
    }

    .annual-pass-option {
        flex: 1;

        .media {
            height: auto;

            @media (--from-large-screen-w) {
                flex-basis: rem(180);
            }
        }

        .content {
            .description {
                width: 100%;
                font-weight: $weight-normal;
                margin-top: rem(20);
            }
        }
    }
}
