/*
    Title Group
 */
.title-group {
    position: relative;

    width: 100%;
    padding-top: rem(20);
    padding-right: rem(30);
    padding-bottom: rem(10);
    padding-left: rem(30);

    color: $color-dark;

    box-shadow: $default-box-shadow;

    @media (--from-normal-screen-w) {
        padding-top: rem(50);
        padding-right: rem(30);
        padding-bottom: rem(20);
        padding-left: rem(45);
    }


    // States
    &.light {
        color: $color-light;
    }

    // Elements
    & > * {
        @media (--from-normal-screen-w) {
            max-width: rem(330);
        }
    }

    h5 {
        color: $color-dark-tint-3;
    }

    h2 {
        position: relative;

        margin-bottom: rem(20);


        // Elements
        &::before {
            position: absolute;
            left: rem(-30);

            display: block;
            width: rem(3);
            height: rem(80);

            background-color: $color-primary;

            content: '';

            @media (--from-normal-screen-w) {
                left: rem(-45);
            }
        }
    }

    .button {
        @media (--from-medium-screen-w) {
            margin-top: rem(10);
        }

        &.inline {
            @media (--from-medium-screen-w) {
                margin-top: 0;
            }
        }
    }
}
