/*
    Line
 */
hr {
    display: block;
    height: 0;
    margin-top: 0;
    margin-bottom: rem(40);

    background-position: top left;
    border: 0;
    border-bottom: rem(2) solid $color-dark-tint-3;
}
