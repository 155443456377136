/*
    Search Results
 */
.search-results {
    position: relative;


    // Elements
    &-inner {
        display: flex;
        flex-wrap: wrap;
        margin-right: rem(-10);
        margin-left: rem(-10);
    }

    .search-result {
        flex-basis: calc(100% / 2);
        padding-right: rem(10);
        padding-bottom: rem(20);
        padding-left: rem(10);

        @media (--from-medium-screen-w) {
            flex-basis: calc(100% / 3);
        }
    }
}
