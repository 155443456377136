/*
    Form
*/
form {
    fieldset {
        padding: 0;
        margin: 0;

        border: 0;
    }

    .form-group {
        display: flex;
        flex-direction: column;

        @media (--from-small-screen-w) {
            justify-content: flex-start;
            align-items: stretch;
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: rem(-20);
        }

        > * {
            @media (--from-small-screen-w) {
                margin-right: rem(20);
            }
        }

        > .form-field {
            width: 100%;

            @media (--from-small-screen-w) {
                width: calc(50% - rem(20));
            }

            &.full-width {
                width: 100%;
                margin-right: 0;
            }

            &.full-width-with-btn {
                flex: 1;
            }
        }
    }

    .section {
        .title {
            margin-bottom: rem(20);
        }
    }

    .error-message,
    .success-message,
    .helper-message {
        margin-bottom: rem(20);
        font-size: rem(14);
        font-style: italic;
        line-height: 1.2;
    }

    .error-message {
        color: $color-error;
    }

    .success-message {
        color: $color-primary;
    }
    
    .helper-message {
        color: $color-primary;
    }
}
