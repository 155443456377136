/*
    Highlter
*/
.highlighter {
    position: relative;

    background-color: $color-light;
    box-shadow: $large-box-shadow;


    // Elements
    &::before {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: rem(8);
        height: 100%;

        background-color: $color-primary;

        content: '';
    }
}
