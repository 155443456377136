/*
    Button
 */
button,
button[type='button'],
button[type='submit'] {
    padding: 0;

    line-height: 1;
    white-space: nowrap;

    cursor: pointer;
    background: none;
    border: none;

    appearance: none;
}

a.button {
    line-height: 1;
    text-decoration: none;
}

a.button,
button.button,
.button[type='button'],
.button[type='submit'] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: rem(46);
    padding-right: rem(30);
    padding-left: rem(30);

    color: $color-light;
    font-family: $font-secondary;
    font-size: rem(14);
    font-weight: $weight-semibold;
    line-height: rem(20);
    text-align: center;
    white-space: nowrap;

    background-color: $color-dark;
    border: 2px solid transparent;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s;


    // States
    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;
    }

    &:hover {
        background-color: $color-dark-tint-1;
    }


    // Types
    &.block {
        display: block;
    }

    &.split {
        justify-content: space-between;
    }

    &.outline {
        background-color: transparent;
        border-color: $color-light;

        &:hover {
            background-color: $color-overlay-opac-1;
        }
    }

    // TODO: Replace this functionality once button markup is independent from CMS see: formatContent()
    &.has-chevron {
        &::after {
            position: relative;
            top: rem(2);

            display: block;
            width: rem(40);
            height: rem(40);

            background-image: url($base64-icon-chevron);
            background-size: cover;

            content: '';

            transform: rotate(-90deg);
        }
    }


    // Sizes
    &.small {
        height: rem(40);
        padding-right: rem(20);
        padding-left: rem(20);

        font-size: rem(12);
        letter-spacing: rem(1);
    }

    &.large {
        @media (--from-small-screen-w) {
            min-width: rem(160);
        }

        @media (--from-medium-screen-w) {
            height: rem(58);
            min-width: rem(250);
            padding-right: rem(30);
            padding-left: rem(30);

            font-size: rem(16);
        }
    }

    &.centered {
        display: block;
        margin: auto;
    }

    &.is-hide {
        display: none;
    }


    // Themes
    &.primary {
        color: $color-dark;

        background-color: $color-primary;


        // States
        &:hover {
            background-color: $color-primary-tint-1;
        }
    }

    &.overlay {
        color: $color-primary;

        background-color: $color-overlay-opac-1;


        // States
        &:hover {
            background-color: $color-overlay;
        }
    }

    &.inline {
        height: auto;
        padding-top: rem(5);
        padding-right: 0;
        padding-bottom: rem(5);
        padding-left: 0;

        color: $color-primary;
        font-size: rem(18);
        letter-spacing: 0.02em;
        text-transform: none;

        background-color: transparent;
        border: none;

        @media (--from-normal-screen-w) {
            font-size: rem(18);
        }


        // States
        &:hover {
            opacity: 0.5;
        }
    }


    // Elements
    .icon {
        margin-right: rem(-20); // Fix icon alignment
        margin-left: rem(5);

        transform: rotate(-90deg);
    }
}
