/*
    Newsletter
 */
.newsletter {

    @media (--to-normal-screen-w) {
        position: relative;
    }

    @media (--from-normal-screen-w) {
        display: flex;
        flex-wrap: nowrap;
    }


    // Elements
    &-input {
        display: block;
        min-width: rem(280);
        padding: rem(10) rem(20);

        color: $color-dark;
        font-size: rem(16);

        background-color: transparent;
        border: 1px solid $color-dark;
        border-radius: rem(3);

        appearance: none; // TODO: Remove after default inputs are setup and add class .input on the markup

        @media (--from-small-screen-w) {
            min-width: rem(150);
        }

        @media (--from-normal-screen-w) {
            display: inline-flex;
            max-width: rem(200);
        }


        // Elements
        &::placeholder {
            color: $color-dark;
        }
    }

    &-field:not(:first-of-type) {
        @media (--to-normal-screen-w) {
            padding-top: rem(10);
        }

        @media (--from-normal-screen-w) {
            padding-left: rem(10);
        }
    }

    &-validation {
        padding-left: rem(20);
        margin: 0;

        color: $color-error;
        font-size: rem(14);
        font-style: italic;

        &.error {
            order: 1;
        }
    }

    .is-invalid {
        border: 1px solid $color-error;
    }

    .button {
        width: rem(100);

        &:disabled {
            opacity: 0.8;
        }

        @media (--to-normal-screen-w) {
            margin-top: rem(25);
        }

        @media (--from-normal-screen-w) {
            margin-left: rem(20);
        }
    }
}

// Styles for modal signup form
.newsletter.newsletter-modal {
    display: block;
    width: rem(400);
    max-width: 100%;

    .newsletter-field {
        padding-left: 0;
        margin-bottom: rem(20);
    }

    .newsletter-input {
        width: rem(300);
        max-width: 100%;
    }

    .button {
        margin: 0;
    }

    .terms-and-privacy a {
        color: $color-primary;

        &:hover {
            color: $color-primary-tint-1;
        }
    }
}
