/*
    Text Block
 */
.text-block {

    // Elements
    .inner {
        padding-top: rem(30);
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);

        background-color: $color-light;
        box-shadow: $default-box-shadow;

        @media (--from-small-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }

        @media (--from-normal-screen-w) {
            display: flex;
            justify-content: space-between;
            padding-top: rem(50);
            padding-bottom: rem(30);
        }
    }

    .content {
        @media (--from-normal-screen-w) {
            flex-grow: 1;
            padding-right: rem(40);
            padding-left: rem(50);
        }

        &:only-child {
            @media (--from-normal-screen-w) {
                padding-right: rem(50);
            }
        }
    }

    .content-group {
        @media (--from-normal-screen-w) {
            padding-left: rem(30);
        }
    }
}
