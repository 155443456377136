/*
    Caption Carousel
 */
.caption-carousel {
    position: relative;

    margin-bottom: rem($block-spacing);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(40);
        margin-left: auto;
    }

    @media (--from-normal-screen-w) {
        margin-bottom: rem(60);
    }


    // Types
    &.overlaid {
        @media (--from-medium-screen-w) {
            margin-top: rem(-$section-overlap);
        }


        // Elements
        .inner {
            color: $color-light;

            background-color: $color-dark-opac-2;
        }

        .carousel-item {

            // Elements
            h3 {
                margin-bottom: rem(10);
            }

            a {
                color: $color-light;
                text-decoration: none;
            }
        }

        .change-slide {
            color: $color-light;

            background-color: $color-dark-opac-2;


            // States
            &:hover {
                color: $color-primary;
            }

            &.is-disabled {

                // Elements
                .icon {
                    color: $color-light-shade-4;
                }
            }
        }

        .carousel-item {
            @media (--to-small-screen-w) {
                padding-right: rem(20);
                padding-left: rem(20);
            }
        }
    }


    // Elements
    .inner {
        position: relative;
    }

    .constrain-width {
        @media (--to-medium-screen-w) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .wrapper {
        position: relative;

        flex-grow: 1;
        width: 100%;


        // Overlay
        &::before,
        &::after {
            display: block;
            width: rem(80);

            content: '';
        }
    }

    .change-slide {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-caption-carousel-slide;

        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(100);
        height: 100%;
        padding: 0;
        overflow: hidden;

        color: $color-dark-tint-2;

        border: none;

        transition: 0.2s background-color ease-in-out;
        appearance: none;

        @media (--to-small-screen-w) {
            top: rem(-50);

            width: rem(50);
            height: rem(50);
        }


        // States
        &:hover {
            color: $color-primary;
        }

        &.is-disabled {
            @media (--to-small-screen-w) {
                color: $color-light;

                background-color: color($color-dark alpha(50%));
            }

            .icon {
                color: $color-dark;

                opacity: 0.2;
            }
        }


        // Types
        &.previous {
            @media (--to-small-screen-w) {
                right: rem(50);
                left: auto;
            }

            .icon {
                transform: rotate(90deg);
            }
        }

        &.next {
            right: 0;
            left: auto;

            .icon {
                transform: rotate(-90deg);
            }
        }


        // Elements
        .icon {
            width: 100%;
            height: 100%;

            transition: 0.2s opacity ease-in-out;
        }
    }

    .carousel-item {
        padding-top: rem(20);

        @media (--from-small-screen-w) {
            padding-top: rem(30);
            padding-right: rem(120);
            padding-bottom: rem(10);
            padding-left: rem(120);
        }

        @media (--from-medium-screen-w) {
            padding-right: rem(150);
            padding-left: rem(150);
        }
    }
}
