/*
    Stackla Tabs Block
 */
.stackla-tabs-block {
    margin-bottom: rem(40);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(80);
    }


    // Elements
    .section-header {
        margin-bottom: 0;
    }

    .filter-by-row {
        margin-bottom: rem($block-spacing);

        @media (--from-small-screen-w) {
            display: flex;
            align-items: baseline;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: rem(40);
        }
    }

    .filter-by-label {
        font-size: rem(18);
        font-weight: $weight-bold;
        line-height: rem(39);
        text-align: center;
        white-space: nowrap;


        // Elements
        &-inner {
            padding-right: rem(15);
            padding-left: rem(15);
            margin-bottom: rem(3);

            @media (--from-small-screen-w) {
                margin-bottom: rem(10);
            }
        }
    }

    .stackla-tab {
        display: none; // Only the active tab needs to be shown


        // States
        &.is-active {
            display: block;
        }
    }
}
