/*
    Footer Associations
*/
.footer-associations {
    padding-bottom: rem(40);

    background-color: $color-dark-opac-2;

    @media (--from-medium-screen-w) {
        padding-bottom: rem(50);
    }


    // Elements
    &-inner {
        @media (--from-small-screen-w) {
            display: flex;
        }
    }

    &-content {

        color: $color-light;

        @media (--from-small-screen-w) {
            flex-basis: 50%;
            flex-shrink: 0;
            margin-right: rem(40);
        }

        p {
            max-width: rem(450);

            font-size: rem(14);
        }
    }

    &-logos {
        display: flex;
        flex-basis: 50%;
        flex-wrap: wrap;
        justify-content: center;

        // Elements
        .logo {
            display: flex;
            flex-basis: 50%;
            justify-content: center;
            margin-bottom: rem(10);

            @media (--from-small-screen-w) {
                align-items: flex-start;
                flex-basis: 33%;
                margin-bottom: 0;
            }
        }
    }
}
