/*
    Tickets section on the tickets page
 */
.tickets-section {
    .ticket-category-subheading {
        margin-bottom: rem(17);

        @media (--from-flexi-tablet-screen-w) {
            display: none;
        }
    }

    .ticket-category-description {
        flex-grow: 1
    }

    // Hide gift option for now (we don't remove because that will ruin the layout on desktop)
    .day-pass-gift {
        visibility: hidden;

        @media (--to-medium-screen-w) {
            display: none;
        }
    }
}
