/*
    Footer Information
 */
.footer-information {
    position: relative;

    padding-top: rem(20);
    padding-bottom: rem(30);

    color: $color-light;

    background-color: $color-dark-opac-2;

    @media (--from-medium-screen-w) {
        padding-top: rem(30);
    }

    @media (--from-normal-screen-w) {
        padding-top: rem(60);
    }


    // Elements
    .inner {
        padding-bottom: rem(30);

        border-bottom: rem(1) solid $color-light;

        @media (--from-normal-screen-w) {
            display: flex;
        }
    }

    .institutional {
        @media (--from-medium-screen-w) {
            display: flex;
        }


        // Elements
        .contact-information {
            flex-grow: 1;

            @media (--from-medium-screen-w) and (--to-normal-screen-w) {
                display: flex;
                justify-content: space-between;
            }

            .hours {
                font-weight: $weight-normal;
            }
        }
    }

    .logo {
        @media (--to-medium-screen-w) {
            margin-top: rem(20);
            margin-bottom: rem(30);
        }

        @media (--from-small-screen-w) {
            width: rem(180);
        }

        .image {
            width: rem(130);
            height: auto;
        }
    }

    .fine-text,
    .link {
        font-family: $font-secondary;
    }

    .link {
        display: flex;
        align-items: center;

        color: $color-light;
        text-decoration: none;


        // States
        &:hover {
            text-decoration: underline;
        }


        // Types
        &:not(:last-child) {
            margin-bottom: rem(10);
        }

        &.title {
            margin-top: rem(-10);


            // Elements
            .icon {
                width: rem(30);
                height: rem(30);

                transform: rotate(-90deg);
            }
        }


        // Elements
        .icon {
            width: rem(20);
            height: rem(20);
        }
    }

    .opening-hours {
        .title {
            margin-bottom: rem(10);
        }

        .fine-text {
            display: block;

            font-size: rem(14);
            font-weight: $weight-normal;
        }

        .heading-2 {
            display: inline-block;

            margin-bottom: rem(10);
        }
    }

    .footer-navigation {
        @media (--to-small-screen-w) {
            display: none;
        }

        @media (--from-small-screen-w) {
            display: flex;
            margin-top: rem(45);
        }

        @media (--from-medium-screen-w) and (--to-normal-screen-w) {
            padding-left: rem(180);
        }

        @media (--from-normal-screen-w) {
            flex-grow: 1;
            padding-left: rem(80);
            margin-top: 0;
        }

        .item {
            flex-basis: calc(100% / 3);
        }
    }
}
