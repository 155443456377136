/*
    Block
 */
.block {

    // Elements
    .inner {
        padding-top: rem(30);
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);

        background-color: $color-light;
        box-shadow: $default-box-shadow;

        @media (--from-small-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(50);
            padding-bottom: rem(30);
        }
    }

    .title {
        margin-bottom: rem(3);

        font-size: rem(22);
        font-weight: $weight-bold;


        // Types
        &.center {
            text-align: center;
        }
    }

    .subtitle {
        margin-top: rem(8);
        margin-bottom: rem(20);
    }
}
