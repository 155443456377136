/*
    Directions
 */
.directions {
    position: relative;

    max-width: $constrain-width-x-large;
    padding-top: rem(60);
    padding-bottom: rem(40);
    margin-right: auto;
    margin-left: auto;

    .sub-introduction {
        @media (--to-small-screen-w) {
            padding-top: rem(20);
        }
    }
}
