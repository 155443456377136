// Annual pass section is based on flexi item but needs some overrides
$price-tag-color: $color-primary;
$price-tag-pad-right: rem(15); // also adjust .cta-row padding-right

.annual-pass-wrapper {
    // Remove padding from outer title-group because CTA needs to be full width
    padding: 0;
    padding-bottom: rem(10);
    justify-content: space-between;

    // Buy/renew annual pass buttons
    .title-group-button-wrapper {
        padding-right: rem(20);
        padding-left: rem(20);
        .button {
            margin-bottom: rem(10);
        }
    }
}

// consistent with title-group paddings
.annual-pass-description {
    padding-top: rem(20);
    padding-right: rem(30);
    padding-left: rem(30);

    @media (--from-large-screen-w) {
        padding-top: rem(50);
        padding-right: rem(30);
        padding-left: rem(45);
    }

    .membership-heading {
        font-size: rem(18);
        font-weight: $weight-semibold;
        margin-bottom: rem(20);
    }
}

.annual-pass-options-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: rem(10);
    height: 100%;
}

// CTA box for an annual pass option
.annual-pass-option {
    display: block;
    margin: rem($block-spacing);
    box-shadow: $default-box-shadow;
    transition: $transition-box-hover;
    text-decoration: none;
    
    // Top and bottom on mobile
    // Horizontal flex on desktop
    @media (--from-large-screen-w) {
        margin: rem($block-spacing) rem(40);
        display: flex;
    }

    &:hover {
        box-shadow: $large-box-shadow;
    }

    &.isHidden {
        display: none;

        @media (--from-medium-screen-w) {
            order: 1;
            display: flex;
            visibility: hidden;
        }
    }

    .media {
        display: flex;

        width: 100%;
        height: 0;

        @media (--from-medium-screen-w) {
            height: rem(130);
        }
        
        @media (--from-large-screen-w) {
            height: auto;
            flex-basis: rem(150);
            flex-grow: 0;
        }
    }

    .media-inner {
        position: relative;

        flex-grow: 1;

        background-color: $color-light-shade-2;
        background-position: center;
        background-size: cover;
    }

    .content {
        color: $color-black;
        position: relative;
        padding: rem(20);
        flex-grow: 1;
        
        // A row of content
        .row {
            width: 100%;
            font-weight: $weight-normal;

            > div {
                display: inline-block;
            }
        }

        .title,b {
            font-weight: $weight-semibold;

            .subtitle {
                font-weight: $weight-normal;
                font-style: italic;
                font-size: rem(12);
            }
        }

        .price-tag {
            padding-left: rem(20);
        }

        // float clearfix
        // .row::after {
        //     content: "";
        //     clear: both;
        //     display: table;
        //   }
        
        // .price-tag {
        //     float: right;
        //     color: white;

        //     // Trapezoid box
        //     height: 0;
        //     margin: 0px;
        //     border: 0 solid transparent;
        //     border-left-width: 15px;
        //     border-right-width: 0px;
        //     border-bottom: 32px solid $price-tag-color;

        //     // Price text goes in here
        //     .price-label {
        //         margin: 5px;
        //     }
        // }

        // // Extruding line under the price tag
        // .price-row {
        //     position: relative;

        //     &:before {
        //         content: '';
        //         width: rem(150);
        //         position: absolute;
        //         height: 2px;
        //         bottom: 0;
        //         right: $price-tag-pad-right;
        //         background: $price-tag-color;
        //     }
        // }

        .cta-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $color-light-shade-2;
            margin-top: rem(10);
        }

        .cta-arrow {
            color: $color-primary;

            .icon {
                transform: rotate(-90deg);
                vertical-align: middle;
            }
        }
    }
}

// For membership tabs
.flexi-item.annual-pass {
    .membership-tabs {
        display: flex;
        gap: 10px;
        margin-bottom: 2rem;
        height: 3rem;

        @media (--from-normal-screen-w) {
            gap: 20px;
        }
    }
    
    .tab-button {
        padding: 0.5rem 1rem;
        background: $color-light;
        cursor: pointer;
        border-radius: rem(25);
        flex: 1;
        font-weight: $weight-semibold;
        color: $color-dark;
        border: 1px solid $color-light-shade-2;
        
    }
    
    .tab-button.active {
        background: $color-primary;
        color: $color-light;
        border-color: $color-primary;
    }
}

// Day pass section appear on the left
@media (--from-medium-screen-w) {
    .flexi-item {
        &.annual-pass {
            order: 2;
        }
        &.day-pass {
            order: 1;
        }
    }
}
