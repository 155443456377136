/*
    Footer Hero
 */
.footer-hero {
    color: $color-dark;

    background-color: $color-primary;
    background-position: 34%;
    background-repeat: no-repeat;
    background-size: cover;

    @media (--to-small-screen-w) {
        position: relative;
    }

    @media (--from-medium-screen-w) {
        background-position-x: 0;
        background-position-y: 34%;
    }


    // Elements
    &::before {
        opacity: 0.5;
    }

    .inner {
        padding-top: rem(40);
        padding-bottom: rem(40);

        @media (--to-small-screen-w) {
            min-height: rem(370);
            padding-bottom: rem(80);
        }

        @media (--from-small-screen-w) and (--to-medium-screen-w) {
            min-height: rem(405);
        }

        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;
            min-height: rem(345);
            padding-top: rem(60);
        }

        @media (--from-normal-screen-w) {
            min-height: rem(205);
        }

        @media (--from-large-screen-w) {
            height: rem(300);
            padding-top: rem(120);
        }


        // Elements
        .title {
            margin-bottom: rem(5);
        }

        .title,
        .subtitle {
            color: $color-dark;
        }
    }

    .heading-group {
        position: relative;

        max-width: rem(400);
        margin-right: rem(20);

        @media (--from-small-screen-w) {
            max-width: rem(860);
        }

        .terms-and-privacy {
            margin-top: rem(10);
    
            a {
                margin-left: rem(5);

                color: inherit;

                &:hover{
                    color: $color-white;
                }
            }
        }
    }

    .social-block {
        position: relative;

        flex-shrink: 0;

        @media (--to-small-screen-w) {
            position: absolute;
            bottom: 0;
            left: 0;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: rem(40) rem(20) rem(20);
        }

        @media (--from-medium-screen-w) {
            padding-top: rem(100);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(40);
        }

        // Elements
        .link {
            display: inline-block;

            color: $color-dark;

            transition: opacity 0.2s;


            // States
            &:hover {
                opacity: 0.8;
            }


            // Types
            &:not(:first-child) {
                margin-left: rem(20);
            }
        }
    }
}
