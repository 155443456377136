/*
    Navigation
 */
.navigation {
    @media (--to-normal-screen-w) {
        position: fixed;
        top: rem(60);
        left: 0;

        width: 100%;
        height: calc(100% - rem(60));

        opacity: 0;

        transform: translateX(100%);
    }

    @media (--from-normal-screen-w) {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        height: rem(100);
        margin-left: auto;

        background-color: $color-light;

        .feature-zoo-secondary-nav & {
            height: rem(115);
        }
    }


    // States
    &.is-active {
        @media (--to-normal-screen-w) {
            opacity: 1;

            transform: translateX(0);
        }
    }

    // Elements
    &-secondary {
        display: flex;
        align-items: center;

        @media (--to-normal-screen-w) {
            display: none;
        }

        // Elements
        &-item {
            display: inline-block;
            padding-top: rem(3);
            padding-bottom: rem(3);

            color: $color-dark;
            font-size: rem(14);
            text-decoration: none;

            // Pseudo
            &:hover {
                color: $color-primary;
            }


            // Types
            &:not(:last-child) {
                margin-right: rem(22);
            }

            // Members portal nav item
            &.portal-nav-item {
                position: relative;

                .dropdown-toggle-btn {
                    height: rem(30);
                    cursor: pointer;
                    color: currentColor;
                    transition: color 0.2s linear;
                }

                .portal-nav-dropdown {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    min-width: rem(220);
                    max-width: rem(300);
                    background-color: $color-light;
                    box-shadow: $default-box-shadow;
                    z-index: 1;
                    animation: animate-fade-down 0.25s ease-in-out;
                }

                .user-info,
                .nav-links-wrapper {
                    display: flex;
                    color: $color-dark;
                    padding: rem(10) rem(16);
                }

                .nav-links-wrapper {
                    flex-direction: column;
                    align-items: flex-start;

                    & > * {
                        margin-bottom: rem(10);
                    }

                    .nav-link {
                        color: $color-dark;
                        text-decoration: none;

                        &:hover,
                        &.is-active {
                            color: $color-primary;
                        }

                        &.is-active {
                            font-weight: $weight-semibold;
                        }
                    }
                }

                hr {
                    width: 100%;
                    border-color: $color-light-shade-2;
                    margin-bottom: 0;
                }

                // State
                &.is-active {
                    color: $color-primary;

                    .portal-nav-dropdown {
                        display: flex;
                    }
                }
            }
        }
    }

    &-primary {
        @media (--to-normal-screen-w) {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            -webkit-overflow-scrolling: touch; // stylelint-disable-line property-no-vendor-prefix
            overflow-x: hidden;

            background-color: $color-dark;
        }
    
        @media (--from-normal-screen-w) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        // Members portal dropdown on mobile
        .item.portal-nav-item {
            .user-info .heading span {
                color: $color-light;
            }

            &.is-active {
                .user-info .icon {
                    transform: rotate(0);
                }
            }
        }
    }

    .item {
        position: relative;

        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-wrap: wrap;

        @media (--to-normal-screen-w) {
            border-bottom: 1px solid $color-dark-tint-1;
        }

        @media (--from-normal-screen-w) {
            justify-content: space-between;
            height: rem(60);
            padding-left: rem(23);
        }

        @media (--from-large-screen-w) {
            padding-left: rem(45);
        }


        // States
        &.is-hovered {
            // Use 'is-hovered' class controlled in JS so we can close the
            // dropdown when the route changes on large touch devices
            .navigation-children {
                @media (--from-normal-screen-w) {
                    display: block;
                }
            }
        }

        &.is-active {
            .navigation-children {
                display: block;

                @media (--from-normal-screen-w) {
                    z-index: 2;
                }
            }

            .link {
                @media (--to-normal-screen-w) {
                    color: $color-primary;
                }
            }

            .icon {
                @media (--to-normal-screen-w) {
                    transform: rotate(180deg);
                }
            }
        }

        &.is-mobile-only {
            @media (--from-normal-screen-w) {
                display: none;
            }
        }


        // Types
        &.navigation-cta {
            position: static;

            padding: rem(20);

            border-bottom: 0;

            @media (--from-tiny-screen-w) {
                padding: rem(24);
            }

            @media (--from-small-screen-w) {
                display: none;
            }

            &:not(:last-child) {
                padding-bottom: 0;
            }

            &.desktop {
                @media (--from-normal-screen-w) {
                    display: flex;
                    padding: 0;
                    padding-left: rem(24);
                }

                @media (--from-large-screen-w) {
                    padding-left: rem(40);
                }
            }

            .button {
                @media (--to-small-screen-w) {
                    width: 100%;
                    height: rem(44);

                    font-size: rem(16);
                    text-align: center;
                }

                @media (--from-normal-screen-w) {
                    .feature-zoo-secondary-nav & {
                        height: rem(40);
                    }
                }
                
                &.outline {
                    border-color: $color-primary;
                }
            }
        }


        // Elements
        .icon {
            transition: transform 0.2s;

            @media (--from-normal-screen-w) {
                width: rem(30);
                height: rem(30);
            }
        }

        .link:only-child {
            @media (--from-normal-screen-w) {
                margin-right: rem(10);
            }
        }
    }

    .children-toggle {
        display: flex;
        align-items: center;
        flex-grow: 0;
        justify-content: center;
        padding: 0;

        background-color: transparent;
        border: none;

        appearance: none;

        @media (--to-small-screen-w) {
            padding-right: rem(10);
        }

        @media (--to-normal-screen-w) {
            z-index: $layer-search-children-toggle;

            flex-grow: 100;
            justify-content: flex-end;
            height: rem(60);
            padding-right: rem(20);

            color: $color-light;
        }
    }

    .link {
        display: flex;
        align-items: center;
        flex-grow: 1;

        color: $color-light;
        font-size: rem(16);
        font-weight: $weight-semibold;
        text-decoration: none;
        text-transform: uppercase;

        @media (--to-normal-screen-w) {
            display: flex;
            height: rem(60);
            padding-top: rem(15);
            padding-right: rem(10);
            padding-bottom: rem(15);
            padding-left: rem(25);
        }

        @media (--to-small-screen-w) {
            padding-left: rem(20);
        }

        @media (--from-normal-screen-w) {
            height: rem(30);

            color: $color-dark;
            text-transform: none;
        }


        // States
        &:hover {
            @media (--from-normal-screen-w) {
                color: $color-primary;
            }

            .icon {
                @media (--from-normal-screen-w) {
                    transform: rotate(180deg);
                }
            }
        }

        &.is-active {
            color: $color-primary;

            .icon {
                @media (--to-normal-screen-w) {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .user-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .user-icon {
            margin-right: rem(10);
        }

        .heading {
            font-size: rem(16);
            margin-left: rem(6);
            margin-bottom: 0;
            color: $color-dark-tint-2;

            span {
                color: $color-dark;
                display: inline-block;
                margin-left: rem(6);
            }
        }
    }
}
