/*
    Search Dialog
 */
.search-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-search-dialog;

    display: none;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    background-color: $color-light;


    // States
    &.is-active {
        display: block;
    }


    // Elements
    &-inner {
        padding-top: rem(20);
        padding-bottom: rem(40);

        @media (--from-medium-screen-w) {
            padding-top: rem(30);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(40);
            padding-bottom: rem(80);
        }
    }

    &-actions {
        display: flex;
        justify-content: flex-end;
        padding-top: rem(10);
        padding-bottom: rem(10);

        @media (--from-normal-screen-w) {
            padding-top: rem(30);
        }
    }

    &-close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);

        color: $color-dark;


        // States
        &:hover {
            color: $color-primary;
        }


        // Elements
        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}
