/*
    Content Media Block
 */
.content-media-block {
    margin-bottom: rem($block-spacing);


    // Elements
    .inner {
        margin-right: rem(-$block-spacing-half);
        margin-left: rem(-$block-spacing-half);

        @media (--from-normal-screen-w) {
            display: flex;
        }
    }

    .media,
    .content {
        padding-right: rem($block-spacing-half);
        padding-left: rem($block-spacing-half);
    }

    .media {
        display: flex;
        flex-grow: 1;
    }

    .media-inner {
        position: relative;

        flex-grow: 1;

        background-color: $color-light-shade-2;
        background-position: center;
        background-size: cover;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: $color-dark;
        border: none;
    }

    .content {
        position: relative;

        display: flex;
        flex-basis: calc(40%);
        flex-shrink: 0;

        @media (--to-normal-screen-w) {
            max-width: 100%;
        }

        @media (--from-large-screen-w) {
            flex-basis: calc(100% / 3);
        }

    }

    .background-image {
        overflow: hidden;

        background-color: $color-dark-opac-2;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .button.inline {
        text-align: left;
    }
}
