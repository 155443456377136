/*
    Members portal redeem gift
*/
.members-portal-redeem-gift {

    .form-container {
        max-width: rem(800);

        .title {
            font-weight: $weight-semibold;
    
            // Types
            &.with-tail {
                display: flex;
                align-items: center;
                justify-content: flex-start;
    
                @media (--from-small-screen-w) {
                    justify-content: center;
                }
    
                &::after {
                    display: none;
                    flex: 1;
                    content: '';
                    height: rem(2);
                    background-color: $color-light-shade-3;
                    margin-left: rem(20);
    
                    @media (--from-small-screen-w) {
                        display: block;
                    }
                }
            }
    
            small {
                display: inline-block;
                margin: 0 rem(10);
                color: $color-dark-tint-2;
            }
    
            .icon {
                display: inline-block;
                margin: 0 rem(10);
                width: rem(20);
                height: rem(20);
                color: $color-primary;
            }
        }
    }

    .voucher-verify-form {
        margin-bottom: rem(40);
    }
}