/*
    Fonts
 */
@font-face {
    font-family: Tiempos Fine;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/tiempos-fine/medium.woff') format('woff'),
        url('../../static/fonts/tiempos-fine/medium.woff2') format('woff2');
}

@font-face {
    font-family: Tiempos Fine;
    font-style: italic;
    font-weight: 500;
    src: url('../../static/fonts/tiempos-fine/medium-italic.woff') format('woff'),
        url('../../static/fonts/tiempos-fine/medium-italic.woff2') format('woff2');
}

@font-face {
    font-family: Untitled Sans;
    font-style: normal;
    font-weight: 700;
    src: url('../../static/fonts/untitled-sans/bold.woff') format('woff'),
        url('../../static/fonts/untitled-sans/bold.woff2') format('woff2');
}

@font-face {
    font-family: Untitled Sans;
    font-style: italic;
    font-weight: 700;
    src: url('../../static/fonts/untitled-sans/bold-italic.woff') format('woff'),
        url('../../static/fonts/untitled-sans/bold-italic.woff2') format('woff2');
}

@font-face {
    font-family: Untitled Sans;
    font-style: normal;
    font-weight: 500;
    src: url('../../static/fonts/untitled-sans/medium.woff') format('woff'),
        url('../../static/fonts/untitled-sans/medium.woff2') format('woff2');
}

@font-face {
    font-family: Untitled Sans;
    font-style: italic;
    font-weight: 500;
    src: url('../../static/fonts/untitled-sans/medium-italic.woff') format('woff'),
        url('../../static/fonts/untitled-sans/medium-italic.woff2') format('woff2');
}


@font-face {
    font-family: Untitled Sans;
    font-style: normal;
    font-weight: 400;
    src: url('../../static/fonts/untitled-sans/regular.woff') format('woff'),
        url('../../static/fonts/untitled-sans/regular.woff2') format('woff2');
}

@font-face {
    font-family: Untitled Sans;
    font-style: italic;
    font-weight: 400;
    src: url('../../static/fonts/untitled-sans/regular-italic.woff') format('woff'),
        url('../../static/fonts/untitled-sans/regular-italic.woff2') format('woff2');
}
