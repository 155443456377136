/*
    Search Result
 */
.search-result {

    // Elements
    &-inner {
        display: block;

        text-decoration: none;

        transition: $transition-box-hover;


        // States
        &:hover {
            box-shadow: $box-shadow-dimensions $color-light-shade-2;
        }
    }

    &-image {
        background-color: $color-light-shade-3;
    }

    .tile-description {
        box-shadow: inset 0 0 1px $color-dark-tint-2;

        @media (--to-small-screen-w) {
            padding-right: rem(10);
            padding-left: rem(15);
        }
    }
}
