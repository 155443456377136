/*
    Search Header
 */
.search-header {
    position: relative;

    display: block;
    margin-bottom: rem(20);

    text-align: center;

    @media (--from-normal-screen-w) {
        padding-top: rem(30);
        margin-bottom: rem(40);
    }


    // Elements
    &-title {
        display: block;
    }

    &-input {
        display: inline-block;
        max-width: 100%;

        color: $color-primary;
        line-height: 1.5; // Allow for visible descender overflow
        text-align: center;

        border: none;
        border-bottom: rem(2) solid transparent;

        transition: border-color 0.2s ease-in-out;


        // States
        &:focus {
            border-bottom-color: $color-primary;
            outline: none;
        }


        // Elements
        &::placeholder {
            color: inherit;
        }
    }
}
