/*
    Button Block
 */
.button-block {


    // Elements
    .inner {
        padding-top: rem(30);
        padding-right: rem(20);
        padding-bottom: rem(30);
        padding-left: rem(20);

        background-color: $color-light;

        @media (--from-normal-screen-w) {
            display: flex;
            justify-content: center;
            padding-top: rem(50);
            padding-bottom: rem(50);
        }
    }
}
