/*
    Navigation Toggle
 */
.navigation-toggle {
    @media (--from-normal-screen-w) {
        display: none;
    }


    // States
    &.is-active .menu {
        display: none;
    }

    &:not(.is-active) .close {
        display: none;
    }


    // Elements
    .button-inner {
        @media (--from-small-screen-w) {
            display: flex;
            align-items: center;
            width: rem(80);

            font-size: rem(12);
            font-weight: $weight-bold;
            text-transform: uppercase;
        }
    }

    .button-text {
        @media (--to-small-screen-w) {
            display: none;
        }
    }

    &.ticket,
    &.donate {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(35);
        height: rem(35);

        background-color: $color-primary;
        border-radius: 3px;

        @media (--from-small-screen-w) {
            display: none;
        }
    }

    &.ticket .icon {
        color: $color-light;
    }

    &.donate .icon {
        color: $color-dark;
    }
}
