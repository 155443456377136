/*
    Figure
 */
figure {
    position: relative;

    max-width: 100%;
    margin: 0;
    margin-bottom: rem(20);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(30);
    }


    // Elements
    figcaption {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

figcaption {
    display: inline-block;
    padding: rem(4) rem(15);

    color: $color-light;

    background-color: $color-overlay-opac-3;


    // Elements
    p {
        margin-bottom: 0;

        font-size: rem(14);
    }
}
