/*
    Image Carousel
 */
.image-carousel {

    // Types
    &.variable-width {

        // Elements
        .carousel-item {
            position: relative;

            display: flex;
            flex-direction: column;
            justify-content: center;
            height: rem(300);

            background-color: transparent;

            @media (--from-small-screen-w) {
                height: rem(400);
            }

            @media (--from-medium-screen-w) {
                height: rem(500);
            }

            @media (--from-normal-screen-w) {
                height: rem(600);
            }

            .image {
                flex-grow: 0;
                width: auto;
                max-width: calc(100vw - rem(40));
                max-height: rem(300);

                opacity: 0.3;
                filter: grayscale(50%);

                transition: opacity 0.2s ease-in-out, filter 0.2s ease-in-out;

                @media (--from-small-screen-w) {
                    height: rem(400);
                    max-height: rem(400);
                }

                @media (--from-medium-screen-w) {
                    max-width: calc(100vw - rem(60));
                }

                @media (--from-medium-screen-w) {
                    height: rem(500);
                    max-height: rem(500);
                }

                @media (--from-normal-screen-w) {
                    height: rem(600);
                    max-width: 100%;
                    max-height: rem(600);
                }
            }
        }

        .slick-slide:not(:last-of-type) {
            padding-right: rem(20);
        }

        // Types
        .slick-active .carousel-item img {
            opacity: 1;
            filter: grayscale(0%);
        }
    }

    &.fixed-width {

        .carousel-item {
            position: relative;

            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: rem(300);

            background-color: transparent;
        }
    }


    // Elements
    .carousel-item {
        max-height: rem(650);
        min-height: rem(300);

        background-color: $color-light-shade-3;
    }
}
