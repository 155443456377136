/*
    Preserve Image Ratio
 */
.preserve-image-ratio {
    position: relative;

    display: block;
    width: 100%;
    overflow: hidden;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;


    // Types
    &.standard::before {
        padding-top: calc(3 / 4 * 100%);
    }


    // Elements
    &::before {
        display: block;
        padding-top: calc(9 / 16 * 100%);

        content: '';
    }

    .fixed-image {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-preserve-ratio-fixed-image;

        width: 100%;
        height: auto;

        opacity: 0;
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;

        width: rem(50);
        height: rem(50);

        color: $color-light-shade-4;

        transform: translate(-50%, -50%);

        @media (--from-small-screen-w) {
            width: rem(70);
            height: rem(70);
        }
    }
}
