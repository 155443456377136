/*
    SoundCloud Player
 */
.sound-cloud-player {
    position: relative;

    margin-bottom: rem(20);


    // Elements
    iframe {
        position: relative;

        height: rem(166);
    }

    // Fake loader while iframe loads
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;

        pointer-events: none;

        transform: translate(-50%, -50%);
    }
}
