/*
    Inline Tag
 */
.inline-tag {

    // Elements
    &::before {
        display: inline-block;
        width: 0;
        height: 0;
        margin-right: rem(6);

        border: rem(6) solid transparent;
        border-left: rem(10) solid $color-primary;

        content: '';
    }
}
