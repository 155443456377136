/*
    Pricing Block
 */
.pricing-block {

    // Elements
    .inner {
        padding-top: rem(50);
        padding-right: rem(20);
        padding-bottom: rem(20);
        padding-left: rem(20);

        background-color: $color-light;
        box-shadow: $default-box-shadow;

        @media (--from-small-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }

        @media (--from-normal-screen-w) {
            display: flex;
            flex-wrap: wrap;
            padding-top: rem(70);
            padding-right: rem(60);
            padding-bottom: rem(30);
            padding-left: rem(60);
        }
    }

    .plan-group {
        flex-basis: calc(50% - rem(60));
        margin-bottom: rem(20);


        // Elements
        &-actions {
            display: flex;
            justify-content: center;
        }

        .plan {
            margin-bottom: rem(20);
        }


        // Order
        &:only-child {
            flex-grow: 1;
        }

        &:nth-child(2n) {
            @media (--from-normal-screen-w) {
                padding-left: rem(30);
            }

            // Elements
            .plan .price::before,
            .plan .figure {
                background-color: $color-dark;
            }
        }

        &:nth-child(2n+1) {
            @media (--to-normal-screen-w) {
                margin-bottom: rem(40);
            }

            @media (--from-normal-screen-w) {
                padding-right: rem(30);
            }
        }

        .figure {
            text-decoration: none;
        }
    }
}
