/*
    Sub Introduction
 */
.sub-introduction {
    position: relative;
    z-index: $layer-banner;

    padding-top: rem(15);
    padding-right: rem(20);
    padding-bottom: rem(20);
    padding-left: rem(20);
    margin-top: rem(-$section-overlap-large);

    background-color: $color-light;

    @media (--from-medium-screen-w) {
        padding-top: rem(30);
        padding-bottom: rem(30);
        margin-top: rem(-$section-overlap);
    }

    @media (--from-normal-screen-w) {
        padding-right: rem(60);
        padding-left: rem(60);
    }

    @media (--from-large-screen-w) {
        padding-right: rem(100);
        padding-left: rem(100);
    }


    // Elements
    &-wrapper {
        padding-bottom: rem($block-spacing);
    }

    .inner {
        @media (--from-medium-screen-w) {
            display: flex;
            justify-content: space-between;
        }
    }

    .actions {
        display: flex;
        align-items: center;

        @media (--to-medium-screen-w) {
            padding-left: rem(50);
        }
    }

    .content {
        position: relative;

        flex-basis: 50%;
        flex-grow: 1;
        padding-top: rem(2);
        padding-left: rem(50);

        @media (--to-medium-screen-w) {
            margin-bottom: rem(20);
        }

        @media (--from-medium-screen-w) {
            padding-right: rem(50);
        }


        // Relationship
        & + .content {
            @media (--to-medium-screen-w) {
                margin-top: rem(40);
            }
        }


        // Elements
        .title {
            font-size: rem(28);
        }

        .fixed-icon {
            position: absolute;
            top: rem(2);
            left: 0;

            width: rem(34);
            height: rem(34);

            color: $color-primary;
        }
    }

    .openning-hours {

        // Elements
        .time {
            margin-bottom: 0;

            @media (--from-medium-screen-w) {
                font-size: rem(22);
            }
        }
    }
}
