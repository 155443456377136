/*
    Notification
 */
.notification {
    position: relative;


    // Types
    &.closure {
        background-color: $color-closure;
    }

    &.alert {
        background-color: $color-alert;
    }

    &.maintenance {
        background-color: $color-maintenance;
    }

    &.discount {
        background-color: $color-discount;
    }

    &.wait-time {
        background-color: $color-wait-time;
    }


    // States
    &:not(.is-visible) {
        display: none;
    }


    // Elements
    &-inner {
        display: flex;
        align-items: center;

        padding-top: rem(15);
        padding-bottom: rem(15);

        color: $color-white;
    }

    &-content {
        flex-basis: 100%;

        @media(--to-small-screen-w) {
            padding-right: rem(20);
        }

        @media(--from-small-screen-w) {
            padding-left: rem(25);
        }
    }

    &-icon, &-icon-close {
        color: $color-light;
    }

    &-icon {
        display: none;

        @media(--from-small-screen-w) {
            display: block;
        }
    }

    &-toggle {
        color: $color-light;
    }

    p {
        margin-bottom: 0;

        line-height: 1.4;

        @media (--to-small-screen-w) {
            font-size: rem(16);
        }
    }

    a {
        display: inline-block;

        color: $color-white;
        text-decoration: underline;
    }
}
