/*
    Hero Carousel
*/
.hero-carousel {
    position: relative;


    // States
    &:hover .carousel .change-slide {
        opacity: 1;

        transition: opacity 0.2s ease-in-out 0.2s;

        &.is-disabled {
            opacity: 0.35;
        }
    }

    // Types
    &.primary {
        &::before {
            height: rem(540);
            max-height: calc(100vh - rem(40));
            min-height: rem(500);

            @media (--from-medium-screen-w) {
                height: rem(600);
            }

            @media (--from-normal-screen-w) {
                height: rem(900);
                min-height: rem(600);
                padding-top: rem(100);
            }
        }
    }

    &:not(.primary) {
        &::before {
            max-height: rem(640);
            min-height: rem(220);

            @media (--from-small-screen-w) {
                height: rem(500);
            }

            @media (--from-medium-screen-w) {
                height: rem(550);
            }

            @media (--from-normal-screen-w) {
                height: rem(640);
            }
        }
    }

    // Elements
    &::before {
        display: block;

        content: '';
    }


    &-intro {
        position: absolute;
        top: 40%;
        bottom: 0;
        left: 50%;
        z-index: $layer-banner;

        width: 100%;

        color: $color-light;
        text-align: center;

        transform: translateX(-50%);
    }

    .carousel {

        // Elements
        &-image {
            max-height: rem(640);
            min-height: rem(220);

            @media (--from-small-screen-w) {
                height: rem(500);
            }

            @media (--from-medium-screen-w) {
                height: rem(550);
            }

            @media (--from-normal-screen-w) {
                height: rem(640);
            }

            &.primary {
                max-height: calc(100vh - rem(40));
                min-height: rem(500);

                @media (--from-medium-screen-w) {
                    height: rem(600);
                }

                @media (--from-normal-screen-w) {
                    height: rem(900);
                    min-height: rem(600);
                    padding-top: rem(100); // Allow for header
                }
            }

            content: '';
        }

        &-inner {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 100%;
        }

        .change-slide {
            @media (--to-medium-screen-w) {
                display: none;
            }

            @media (--from-medium-screen-w) {
                opacity: 0;

                transition: opacity 0.2s ease-in-out;
            }
        }
    }
}
