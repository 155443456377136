/*
    Sponsors
 */
.sponsors {
    order: 3;
    padding-top: rem(30);
    padding-bottom: rem(30);

    // Elements
    .inner {
        display: flex;
        flex-direction: column;

        @media (--from-small-screen-w) {
            flex-direction: row;
        }

        @media (--from-medium-screen-w) {
            padding-left: rem(50);
        }

        @media (--from-normal-screen-w) {
            padding-left: rem(85);
        }
    }

    .sponsors-group {
        flex-grow: 1;

        @media (--to-normal-screen-w) {
            &:last-of-type .sponsors-group-inner {
                padding-right: rem(0);
            }
        }

        &:not(:last-of-type) {
            margin-bottom: rem(20);

            @media (--from-small-screen-w) {
                margin-bottom: 0;
            }
        }

        &.major {
            display: none;

            @media (--from-medium-screen-w) {
                display: block;
            }
        }
    }

    .sponsors-group-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (--from-medium-screen-w) {
            display: block;
            padding-right: rem(45);
        }
    }


    .title {
        display: block;
        white-space: nowrap;
    }

    .image-group {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-15);
        justify-content: center;

        @media (--from-medium-screen-w) {
            justify-content: flex-start;
        }

        @media (--from-normal-screen-w) {
            margin-left: rem(-30);
        }


        // Elements
        & > * {
            flex-grow: 0;
            flex-shrink: 1;
        }
    }

    .item {
        display: flex;
        align-items: center;

        width: auto;
        height: auto;
        padding-top: rem(5);
        padding-right: rem(15);
        padding-bottom: rem(5);
        padding-left: rem(15);

        @media (--from-normal-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }


        // Elements
        img {
            max-width: 100%;
            max-height: rem(36);


            &.large {
                max-height: rem(40);
            }
            &.extra-large {
                max-height: rem(68);
            }
        }
    }
}
