/*
    Tile Description
 */
.tile-description {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding-top: rem(10);
    padding-right: rem(20);
    padding-bottom: rem(15);
    padding-left: rem(20);

    background-color: $color-white;

    @media (--to-small-screen-w) {
        padding-right: rem(30);
        padding-left: rem(20);
    }


    // Elements
    & > * {
        margin-bottom: 0;
    }

    &-title {
        color: $color-primary;
    }

    &-time,
    &-type {
        padding-bottom: rem(5);

        color: $color-dark;
    }
}
