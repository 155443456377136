/*
    Button Group
*/
.button-group {
    display: flex;
    align-items: center;
    justify-content: center;

    .button {
        margin-right: rem(10);

        &:last-child {
            margin-right: 0;
        }
    }
}

.go-connect-button-group {
    &-wrapper {
        display: flex;
        justify-content: center;
        margin-top: rem(50);
        margin-bottom: rem(50);
    }
    &-inner {
        position: relative;
        z-index: $layer-button-group-inner;

        background-color: $color-light;
        border-radius: rem(40) rem(40);


        // Elements
        .button {
            z-index: $layer-button-group-button;

            color: $color-dark;
            letter-spacing: 0.1em;
            text-transform: none;

            background-color: transparent;
            outline: none;


            // States
            &.is-active {
                color: $color-light;

                transition: color ease-in-out 0.2s;
            }

            &:hover {
                background-color: transparent;

                &:not(.is-active) {
                    color: $color-primary-tint-1;
                }
            }
        }
    }

    &-active {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $layer-button-group-active;

        height: rem(46);
        min-width: 50%;
        padding-right: rem(30);
        padding-left: rem(30);

        background-color: $color-primary;
        border: 2px solid transparent;
        border-radius: rem(100);

        transform: translateX(0%);
        transition: transform ease-in-out 0.2s;

        @media (--from-medium-screen-w) {
            height: rem(58);
        }

        &.last {
            transform: translateX(100%);
        }
    }
}
