/*
    Checkbox
 */
input[type='checkbox'] {
    width: 0;
    height: 1.2em;

    opacity: 0;


    // States
    &:focus + .checkbox-button {
        outline: -webkit-focus-ring-color auto rem(5);
        // outline-offset: rem(-3);
    }

    &:checked + .checkbox-button {
        border-color: $color-primary;

        .icon {
            opacity: 1;
        }
    }

    &:disabled + .checkbox-button {
        cursor: not-allowed;
    }
}


.checkbox-button {
    position: relative;

    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.3em;

    vertical-align: -0.3em;

    background-color: $color-light;
    border: rem(1) solid $color-light-shade-3;
    border-radius: 0.25em;

    content: '';

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 0.9em;
        height: 0.9em;
        max-width: 0.9em;

        color: $color-primary;

        opacity: 0;

        transform: translate(-50%, -50%);
    }
}

.checkbox-wrapper {
    display: block;
    padding-left: 1.5em;
    margin-top: rem(10);
    margin-bottom: rem(10);

    text-indent: -1.5em;
}
