/*
    Day Pass
*/
.day-pass {

    // Types
    &.no-banner {
        position: relative;

        margin-top: rem(140);

        @media (--from-small-screen-w) {
            margin-top: rem(160);
        }

        @media (--from-normal-screen-w) {
            margin-top: rem(180);
        }
    }


    // Elements
    &-options {

        // Elements
        &-item {
            z-index: $layer-rezdy-iframe;

            height: 0;
            overflow: hidden;

            opacity: 0;


            // State
            &.is-active {
                z-index: $layer-rezdy-iframe-active;

                height: auto;
                overflow: visible;

                opacity: 1;
            }
        }
    }
}
