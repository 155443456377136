/*
    Form field
 */
.form-field {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: rem(16);

    // Input container (label + input wrapper)
    .input-container {

    }

    // Input wrapper (input + icon)
    .input-wrapper {
        position: relative;
    
        .icon {
            position: absolute;
            top: 50%;
            right: rem(10);
            width: rem(20);
            height: rem(20);
            transform: translateY(-50%);
        }
    }

    // State
    &.small {
        width: rem(150);
    }

    &.medium {
        width: rem(220);
    }

    &.normal {
        width: rem(300);
    }

    &.large {
        width: rem(420);
    }

    &.is-required .label:after {
        color: $color-error;

        content: '*';
    }

    .error-message,
    .success-message,
    .helper-message {
        margin: 0;
        line-height: 1.2;
    }

    .error-message,
    .success-message {
        display: none;
    }

    &.is-invalid,
    &.is-valid,
    &.has-helper {
        margin-bottom: rem(8);
    }

    &.is-invalid {
        color: $color-error;


        input,
        .input {
            border: rem(1) solid $color-error;
        }

        .error-message {
            display: block;
        }
    }

    &.is-valid {
        color: $color-primary;

        input,
        .input {
            border: rem(1) solid $color-primary;
        }

        .success-message {
            display: block;
        }
    }

    // Date picker with input
    &.date-picker {
        input {
            cursor: default;
        }

        .rdp {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: rem(20);
            position: absolute;
            background-color: $color-light;
            z-index: 1;
            margin: 0;
            border-radius: rem(5);
            border: rem(1) solid $color-light-shade-3;
            color: $color-dark;

            .rdp-cell > button.rdp-button.rdp-day {
                &:hover,
                &:focus {
                    background-color: $color-light-shade-3;
                }
    
                &:focus {
                    border-color: $color-primary;
                }

                &.rdp-day_selected {
                    background-color: $color-primary;
                    color: $color-dark;
                }
            }
        }
    }

}

// For displaying an empty input space on desktop
.form-field-empty {
    @extend .form-field;
    
    @media (--to-small-screen-w) {
        display: none;
    }
}
