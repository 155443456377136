/*
    List
 */
li {
    position: relative;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: rem(20);
    margin-bottom: rem(10);


    // Elements
    &::before {
        position: absolute;
        top: rem(9);

        margin-left: rem(-18);

        content: '';
    }
}

ul,
ol {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: rem(30);

    list-style: none;
    list-style-position: inside;

    @media (--from-medium-screen-w) {
        margin-bottom: rem(40);
    }
}

ul li::before {
    top: rem(5);

    height: rem(16);
    margin-right: rem(20);

    border-left: 2px solid $color-primary;
}

ol li::before {
    margin-right: rem(12);

    border: rem(5) solid transparent;
    border-left: rem(7) solid $color-light-shade-5;
}
