/*
    Label with the validation message
 */
.label-validation {
    display: inline-block;
    margin-right: rem(5);
    margin-bottom: rem(5);

    color: $color-primary;
    font-style: italic;
}
