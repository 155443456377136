/*
    Members portal
*/
.members-portal {
    display: flex;

    &-navigation {
        flex-basis: rem(210);
        display: none;
        flex-direction: column;
        align-items: flex-end;

        @media (--from-normal-screen-w) {
            display: flex;
            padding: rem(20) rem(30);
        }

        @media (--from-large-screen-w) {
            display: flex;
            flex-basis: rem(260);
        }

        .nav-link {
            margin-bottom: rem(20);
            font-size: rem(16);
            color: $color-dark;
            text-decoration: none;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: rem(2);
                background-color: $color-primary;
                transition: width 0.2s linear;
            }

            &.is-active {
                font-weight: 500;

                &::before {
                    width: 100%;
                }
            } 

            &:hover {
                color: $color-primary;
            }
        }
    }

    &-content {
        flex: 1;
        padding-top: rem(20);
        max-width: 100%;

        @media (--from-normal-screen-w) {
            max-width: calc(100% - rem(210));
            border-left: rem(1) solid $color-light-shade-3;
            padding-left: rem(30);
        }

        @media (--from-large-screen-w) {
            max-width: calc(100% - rem(260));
            padding-left: rem(50);
        }

        & .constrain-width {
            padding-left: 0;
            padding-right: 0;
        }
    }

    //
    .rolling-text-block {
        .visits-chart-container {
            box-shadow: $large-box-shadow;
            padding: rem(20);

            @media (--from-medium-screen-w) {
                padding: rem(30) rem(30) rem(20);
            }

            @media (--from-normal-screen-w) {
                padding: rem(40) rem(40) rem(20);
            }
                
            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: rem(10);
                flex-wrap: wrap;
            }

            .title {
                font-size: rem(28);
                margin-bottom: 0;

                &.text-align-right {
                    text-align: right;
                }
    
                span {
                    display: inline-block;
                    margin-right: rem(6);
                    color: $color-primary;
                }
            }
    
            .subheading {
                margin-bottom: rem(10);
            }

            .line-chart-container,
            .visits-table-container {
                margin-bottom: rem(20);
            }
            
            .line-chart-container {
                position: relative;
                z-index: $layer-line-chart;
                
                .scroll-btn {
                    position: absolute;
                    z-index: 20;
        
                    color: $color-primary;
                    bottom: rem(-6);
        
                    &.next {
                        transform: rotate(-90deg);
                        // change with pass-scan-modal-content width
                        right: rem(-30);
                    }
                    
                    &.prev {
                        transform: rotate(90deg);
                        // change with pass-scan-modal-content width
                        left: rem(-30);
                    }

                    &.hidden {
                        display: none;
                    }

                    // Hide the buttons when for screens where we display the whole graph
                    @media (--from-small-screen-w) and (--to-large-screen-w) {
                        display: none;
                    }
                }

                .line-chart-scroller-wrapper {
                    position: relative;
                    overflow: hidden;
                    height: rem(200);

                    .line-chart-scroller {
                        width: 100%;
                        @media (--to-tiny-screen-w) {
                            position: absolute;
                            width: rem(600);
                        }

                        @media (--from-tiny-screen-w) and (--to-small-screen-w) {
                            position: absolute;
                            width: rem(650);
                        }

                        // 2 columns -> narrow chart
                        @media (--from-large-screen-w) {
                            position: absolute;
                            width: rem(600);
                        }
                    }
                }
            }

            .visits-table-container {
                max-height: rem(140);
                padding-bottom: rem(20);
                overflow-y: auto;
                mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 70%, transparent);
            }

            .visits-table {
                width: 100%;
                border-collapse: collapse;

                td {
                    padding: rem(5) 0;
                }

                tr:not(:last-child) {
                    border-bottom: rem(1) solid $color-light-shade-2;
                }

                .name {
                    font-size: rem(16);
                    font-weight: $weight-semibold;
                }

                .visits {
                    padding-right: rem(5);
                    white-space: nowrap;
                }
            }
        }
    }
}