/*
    Select tickets - quantities
 */
.select-product-quantities {
    border-collapse: collapse;

    // Elements
    &-row {
        // https://stackoverflow.com/questions/71674803/html-tables-has-a-weird-bold-horizontal-line-due-to-border-collapse-property
        border-top: rem(0.01) solid $color-primary;

        &:first-child {
            border: none;
        }
    }

    &-name {
        width: 100%;
        padding-top: rem(12);
        padding-bottom: rem(12);

        vertical-align: middle;

        @media (--from-small-screen-w) {
            padding-left: rem(20);
        }


        // Elements
        &-label {
            font-size: rem(18);
            font-weight: $weight-semibold;
        }

        &-description {
            font-size: rem(14);
        }
    }

    &-price {
        padding-left: rem(5);
        font-size: rem(18);
        font-weight: $weight-semibold;
        white-space: nowrap;
        vertical-align: middle;
        text-align: right;

        @media (--from-small-screen-w) {
            padding-left: rem(20);
            padding-right: rem(20);
        }

        &-old {
            display: block;
            padding-top: rem(13);
            font-size: rem(15);
            font-weight: $weight-normal;
            text-decoration: line-through;
        }
        
        &-final {
            display: block;
            color: $color-primary;
            padding-top: rem(3);
            padding-bottom: rem(13);
            padding-left: rem(8);
        }
    }

    &-quantity {
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        padding-left: rem(5);

        @media (--from-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(20);
        }
    }

    &-quantity-input {
        background-color: $color-light;
        border: rem(2) solid $color-light-shade-3;
        border-radius: rem(100);

        // Elements
        .change-quantity {
            width: rem(34);
            height: rem(34);
            padding-bottom: rem(4);

            color: $color-primary;
            font-size: rem(18);
            line-height: rem(30);

            border-radius: 50%;

            appearance: none;

            &:disabled {
                cursor: not-allowed;
            }
        }

        .quantity {
            display: inline-block;
            min-width: rem(24);

            font-family: $font-secondary;
            font-size: rem(18);
            line-height: rem(34);
            text-align: center;
        }
    }

    &-error {
        color: $color-error;
        padding-bottom: rem(12);

        @media (--from-small-screen-w) {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }
}
