/*
    Flexi Item
 */
.flexi-item {
    display: flex;
    flex-direction: column;
    padding-right: rem($block-spacing-half);
    padding-bottom: rem($block-spacing);
    padding-left: rem($block-spacing-half);

    @media (--from-flexi-tablet-screen-w) {
        flex-basis: calc(100% / 2);
        flex-grow: 1;
        max-width: calc(100% / 2);


        // Types
        &:nth-child(odd):last-of-type {
            flex-direction: row;
            max-width: none;

            // Elements
            .media {
                flex-basis: calc(100% / 2);
                max-width: calc(100% / 2);
            }

            .title-group {
                flex-basis: calc(100% / 2);
                max-width: calc(100% / 2);
            }

            .preserve-image-ratio {
                max-height: none;
            }
        }
    }


    // Types
    &.ticket {
        .title-group {
            color: $color-dark;

            background-color: $color-light;
        }

        // Override to transition to 2 columns on larger screen size
        @media (--to-medium-screen-w) {
            flex-basis: 100%;
            flex-grow: 0;
            max-width: 100%;
        }
    }


    // Elements
    .media {
        background-color: $color-light-shade-2;
        background-position: center;
        background-size: cover;
    }

    .title-group {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: rem(30);
        overflow: hidden;

        color: $color-light;

        background-color: $color-dark-opac-2;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        // Elements
        > * {
            max-width: none;
        }

        &-heading::before {
            content: none;
        }

        &-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &-button-wrapper {
            text-align: center;

            .button {
                margin: 0 rem(5) rem(5) rem(5);

                font-family: $font-secondary;
                font-size: rem(16);
            }
        }
    }

    .preserve-image-ratio {
        max-height: rem(240);
    }
}
