/*
    Page
 */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    padding: 0;
    margin: 0;
}

html {
    position: relative;

    min-width: 320px;
    min-height: 100%;
}

body {
    width: 100%;
    overflow-x: hidden;

    background-color: $color-light;

    &.is-fixed {
        position: fixed;
    }
}

.base {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 100%;
        height: rem(200);

        content: '';

        z-index: -1;
    }
}

.page {
    padding-bottom: rem(40);

    @media (--from-medium-screen-w) {
        padding-bottom: rem(60);
    }
}

.root {}
