/*
    Type
 */
html {
    color: $color-dark;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 1.6;
    word-break: break-word;
    overflow-wrap: break-word;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // Reset
}


// Normal text
p {
    margin-top: 0;
    margin-bottom: rem(30);

    font-size: rem(16);
}

strong {
    font-weight: $weight-bold;
}

em {
    font-style: italic;
}

address {
}

small {
    font-size: rem(14)
}

sup {
    top: -0.3em;

    font-size: rem(12);
}

.error-message {
    margin-bottom: rem(20);
    font-size: rem(14);
    font-style: italic;
    color: $color-error;
    line-height: 1.2;

    &.large {
        font-size: rem(16);
        line-height: 1.5;

        @media (--from-medium-screen-w) {
            font-size: rem(18);
        }
    }
}


// Link
a,
button.link,
button[type='button'].link,
button[type='submit'].link {
    color: $color-primary;
    font-family: inherit;
    font-size: inherit;
    text-decoration: underline;

    transition: color 0.2s ease-in-out;


    // States
    &:hover {
        color: $color-primary-tint-1;
    }
}


// In-page anchors
:target::before {
    display: block;
    height: rem($space-above-in-page-anchors);
    margin-top: rem(-$space-above-in-page-anchors);

    content: '';
}