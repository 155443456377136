/*
    Footer
 */
.footer {
    display: flex;
    flex-direction: column;
    max-width: rem($constrain-width-x-large);
    margin-right: auto;
    margin-left: auto;
    overflow-x: hidden;


    // Elements
    & > * {
        width: 100%;
    }

    .footer-information {
        order: 2;

        @media (--from-small-screen-w) {
            order: 3;
        }
    }

    .footer-hero {
        // If deep link target is /#sign-up
        &[id='sign-up']:target {
            // Add padding top for header
            padding-top: rem($header-height-small);

            @media (--from-normal-screen-w) {
                padding-top: rem($header-height);
            }

            // Prioritise order on mobile
            @media (--to-small-screen-w) {
                order: 1;
            }
        }

        @media (--to-small-screen-w) {
            order: 3;
        }
    }

    .footer-associations {
        order: 2;

        @media (--from-small-screen-w) {
            order: 4;
        }
    }

    .sponsors {
        @media (--from-small-screen-w) {
            order: 5;
        }
    }

    .copyrights {
        @media (--from-small-screen-w) {
            order: 6;
        }
    }

    .title {
        color: $color-dark-tint-3;
    }
}
