/*
    Accordion
 */
.accordion {
    margin-bottom: rem(20);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(40);
    }


    // Elements
    .item.is-active {

        // Elements
        .item-header {

            // Elements
            .title {
                color: $color-primary;
            }

            .icon {
                transform: rotate(180deg);
            }
        }

        .item-content {
            display: block;
        }
    }

    .item-header,
    .item-content {
        padding-left: rem(40);
    }

    .item-header {
        position: relative;

        display: block;
        width: 100%;
        min-height: rem(40);
        padding-top: rem(10);
        padding-bottom: 0;
        overflow: hidden;

        text-align: left;
        white-space: normal;

        background-color: transparent;
        border: none;
        border-bottom: 2px solid $color-light-shade-2;

        appearance: none;

        @media (--from-medium-screen-w) {
            min-height: rem(60);
            padding-top: rem(20);
        }


        // Elements
        .icon {
            position: absolute;
            top: 0;
            left: rem(-5);

            width: rem(40);
            height: rem(40);

            color: $color-primary;

            transition: 0.25s transform ease-in-out;

            @media (--from-medium-screen-w) {
                top: rem(8);

                width: rem(50);
                height: rem(50);
            }
        }

        .title {
            @media (--to-medium-screen-w) {
                margin-bottom: rem(15);
            }
        }
    }

    .item-content {
        display: none;

        padding-top: rem(20);
        padding-right: rem(20);
        padding-bottom: rem(10);

        background-color: $color-light-shade-2;

        @media (--from-medium-screen-w) {
            padding-bottom: rem(20);
        }
    }
}
