/*
    News Highlight
 */
.news-highlight {
    @media (--from-medium-screen-w) {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }


    // Typess
    &.has-articles {
        display: block;

        @media (--from-medium-screen-w) and (--to-normal-screen-w) {
            display: flex;
            flex-direction: row;
        }

        .article-tile {
            @media (--from-medium-screen-w) and (--to-normal-screen-w) {
                flex-basis: 50%;
                max-width: 50%;
            }
        }
    }


    // Elements
    &-item {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        border-top: rem(1) solid $color-light-shade-3;

        @media (--from-normal-screen-w) {
            height: rem(150); // Force equal height vertically
        }


        // Elements
        &-image {
            display: block;
            flex-shrink: 0;

            background-color: $color-dark;
            background-position: center;
            background-size: cover;

            @media (--to-tiny-screen-w) {
                display: none; // Hidden for mobile
            }

            @media (--to-medium-screen-w) {
                flex-basis: rem(110);
                height: rem(110);
            }

            @media (--from-medium-screen-w) {
                flex-basis: rem(150);
                height: rem(150);
            }

            @media (--from-normal-screen-w) {
                height: rem(160);
            }
        }

        &-content {
            position: relative;

            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            padding-top: rem(15);
            padding-right: rem(40);
            padding-bottom: rem(10);

            color: $color-dark-tint-1;
            text-decoration: none;

            @media (--from-normal-screen-w) {
                padding-right: rem(20); // Reduce padding as flag is hidden
            }


            // States
            &:hover {
                color: $color-dark-tint-1;
            }
        }

        .title-wrapper {
            flex-grow: 1;
            margin-bottom: rem(20);
        }

        .title {
            position: relative;

            padding-left: rem(20);
            margin-bottom: 0;
            overflow: hidden;

            font-size: rem(18);
            font-weight: $weight-bold;
            text-decoration: none;

            @media (--from-normal-screen-w) {
                padding-left: rem(17); // Reduce padding to allow for border

                border-left: rem(3) solid $color-primary;
            }
        }

        .flag {
            position: absolute;
            top: 0;
            right: 0;

            width: rem(30);
            height: rem(40);

            background-color: $color-primary;

            @media (--from-normal-screen-w) {
                display: none; // Hide for dekstop screen
            }


            .icon {
                position: relative;
                left: rem(-5);

                height: rem(40);
                max-width: rem(40);

                color: $color-light;

                transform: rotate(-90deg);
            }
        }

        p {
            padding-left: rem(20);
            margin-bottom: 0;
        }
    }
}
