/*
    Portal login / reset password / check email / new email
 */
 .portal-credential-board {
    .inner {
        display: flex;
        justify-content: center;
        align-items: stretch;
        width: 100%;
        box-shadow: $large-box-shadow;

        @media (--from-normal-screen-w) {
            min-height: rem(520);
        }

        & > * {
            width: 100%;

            @media (--from-normal-screen-w) {
                width: 50%;
            }
        }
    }

    .form-container {
        padding: rem(40) rem(20);

        @media (--from-normal-screen-w) {
            align-items: flex-start;
            padding: rem(60) rem(20) rem(30) rem(60);
        }
    }

    .form-section {
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;
        max-width: 100%;
        height: 100%;
        min-height: rem(400);
        margin: auto;

        @media (--from-normal-screen-w) {
            align-items: flex-start;
            margin-left: 0;
        }

        .title {
            margin-bottom: rem(30);
        }

        .loader {
            margin: auto;
        }
    }

    form.form {
        width: rem(360);
        max-width: 100%;
        margin-bottom: rem(30);

        label.label {
            font-size: rem(16);
        }

        label.checkbox-wrapper.label,
        .link {
            font-size: rem(14);
        }

        .link.float-right {
            position: relative;
            float: right;
            z-index: 1;
        }

        button.submit-btn {
            width: 100%;

            @media (--from-normal-screen-w) {
                width: auto;
            }
        }

        .submit {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            @media (--from-normal-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .link-container {
        width: rem(360);
        max-width: 100%;
        margin: auto 0 0 0;

        h4 {
            font-size: rem(16);
            margin: 0;
        }
    }

    .bg-image {
        display: none;

        @media (--from-normal-screen-w) {
            display: block;
        }

        img.image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .error-message {
        margin-bottom: rem(20);
        font-size: rem(14);
        font-style: italic;
        color: $color-error;
        line-height: 1.2;
    }

    // Login
    form.login-form {
        .remeber-me-checkbox {
            margin-bottom: rem(20);

            @media (--from-normal-screen-w) {
                margin-bottom: 0;
            }
        }
    }
 }