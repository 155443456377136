/*
    Rezdy
*/
.rezdy-block {

    // Elements
    &-inner {
        padding-top: rem(50);
        padding-bottom: rem(50);

        background-color: $color-light;

        box-shadow: $default-box-shadow;
    }
}
