/*
    Label
 */
.label {
    display: inline-block;
    margin-right: rem(5);
    margin-bottom: rem(5);

    font-size: rem(18);
}
