/*
    Progress bar
 */

 @keyframes checkIconAnimation {
    to {
        stroke-dashoffset: 0;
    }
}

 .progress-bar {
    margin-top: rem(40);
    margin-bottom: rem(20);

    .steps-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: rem(40);

        @media (--from-medium-screen-w) {
            height: rem(120);
        }

        .step {
            position: relative;
            flex-shrink: 1;
            flex-basis: rem(30);

            @media (--from-medium-screen-w) {
                flex-basis: rem(140);
            }

            &::after {
                position: absolute;
                display: block;
                content: '';
                top: rem(4);
                left: 0;
                width: 100%;
                height: rem(2);
                background-color: $color-primary;
                z-index: -1;
    
                @media (--from-medium-screen-w) {
                    top: rem(20);
                    left: 0;
                }
            }
    
            &:first-child {
                &::after {
                    width: 50%;
                    left: 50%;
                }
            }
    
            &:last-child {
                &::after {
                    width: 50%;
                    right: 50%;
                    left: auto;
                }
            }

            &.is-active,
            &.is-passed:not(.is-disabled):hover {
                .text {
                    color: $color-primary;
                }

                .step-index {
                    border-color: $color-primary-tint-1;
                    background-color: $color-primary-tint-1;
                }
            }
            
            .step-index {
                width: rem(10);
                height: rem(10);

                @media (--from-medium-screen-w) {
                    width: rem(35);
                    height: rem(35);
                }

                .text,
                .icon {
                    display: none;
    
                    @media (--from-medium-screen-w) {
                        display: block;
                    }
                }
            }

            & > .text {
                display: none;
    
                @media (--from-medium-screen-w) {
                    display: block;
                }
            }

            svg.icon {
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                pointer-events: none;
                animation: checkIconAnimation 2s linear forwards;
            }
        }
    }

    // Shared step style
    .step {
        cursor: pointer;
        text-align: center;

        &.is-disabled {
            cursor: not-allowed;

            & > .step-index {
                cursor: not-allowed;
            }
        }

        .text {
            margin: 0;
            white-space: nowrap;
            font-size: rem(14);
            transition: color 0.2s;
        }

        &-index {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(35);
            height: rem(35);
            color: $color-dark-tint-1;
            border: rem(2) solid $color-primary;
            border-radius: 50%;
            background-color: $color-white;
            margin: auto;
            transition: all 0.2s ease-in;

            .icon {
                width: rem(20);
                height: rem(20);
            }
        }

        &.is-active,
        &.is-passed {
            .step-index {
                color: $color-white;
                background-color: $color-primary;
            }
        }

        &.mobile-only {
            @media (--from-medium-screen-w) {
                display: none;
            }
        }
    }
}
