/*
    Plan
 */
.plan {

    // Elements
    .plan-heading {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        font-weight: $weight-bold;
    }

    .price {
        position: relative;
        top: rem(-2);

        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        justify-content: space-between;
        padding-left: rem(5);


        // Elements
        &::before {
            display: block;
            flex-grow: 1;
            width: 100%;
            height: rem(2);

            background-color: $color-dark-tint-3;

            content: '';
        }
    }

    .figure {
        position: relative;

        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: flex-end;
        height: rem(36);
        min-width: rem(60);
        padding-right: rem(8);
        padding-left: rem(8);

        color: $color-light;
        font-size: rem(22);
        font-weight: $weight-bold;
        letter-spacing: 0.01em;
        word-break: normal;
        white-space: nowrap;

        background-color: $color-dark-tint-3;

        @media (--from-medium-screen-w) {
            font-size: rem(22);
        }


        // Elements
        &::before {
            position: absolute;
            top: 0;
            left: rem(-10);

            width: rem(20);
            height: 100%;

            background-color: inherit;

            content: '';

            transform: skew(-20deg);
        }

        span {
            position: relative;
            top: rem(-3);
        }

        sup {
            padding-right: rem(1);
        }
    }

}
