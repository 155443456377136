/*
    Tag
 */
.tag {
    display: inline-block;
    padding-top: rem(8);
    padding-right: rem(20);
    padding-bottom: rem(8);
    padding-left: rem(20);

    color: $color-light;
    font-weight: $weight-normal;

    background-color: $color-primary;

    @media (--from-medium-screen-w) {
        padding-top: rem(10);
        padding-right: rem(30);
        padding-bottom: rem(10);
        padding-left: rem(30);
    }
}
