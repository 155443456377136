/*
    Select Tickets
 */
.select-tickets {

    .buy-tickets-wrapper {
        padding-top: rem(30);
        padding-bottom: rem(20);

        text-align: center;

        .button {
            margin: 0 rem(5) rem(5) rem(5);

            font-family: $font-secondary;
            font-size: rem(16);
        }
    }

    .advance-subtitle {
        margin-bottom: rem(20);
    }
}
