/*
    Toast
*/
.toast {
    position: absolute;
    width: 100%;
    color: $color-light;
    background-color: $color-error;
    padding-top: rem(10);
    padding-bottom: rem(10);
    z-index: -1;
    transition: all 0.2s ease-in;
    opacity: 0;
    transform: translateY(-100%);

    &.is-active {
        opacity: 1;
        transform: translateY(0)
    }

    &-container {
        display: flex;
        height: 100%;
        align-items: center;

        svg.icon {
            width: rem(25);
            height: rem(25);
        }

        p {
            flex: 1;
            margin-bottom: 0;
            margin-left: rem(20);
            font-size: rem(14);
        }

        .close-btn {
            color: $color-light;
        }

        a {
            color: $color-light;
        }
    }
}