/*
    Encounter
 */
.encounter {
    flex-basis: 100%;
    padding-right: rem($block-spacing-half);
    padding-left: rem($block-spacing-half);
    margin-bottom: rem($block-spacing-half);

    @media (--from-tiny-screen-w) {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
    }

    @media (--from-small-screen-w) {
        flex-basis: calc(100% / 3);
        max-width: calc(100% / 3);
    }

    @media (--from-normal-screen-w) {
        flex-basis: calc(100% / 4);
        max-width: calc(100% / 4);
    }


    // Types
    &.up-next .encounter-inner::after {
        position: absolute;
        top: 0;
        right: 0;

        width: 100%;
        height: 100%;

        border: 4px solid $color-primary;

        content: '';
    }


    // Elements
    &-inner {
        position: relative;

        display: block;
        margin-bottom: rem($block-spacing-half);
        overflow: hidden;

        color: $color-dark;
        text-decoration: none;

        background-color: $color-dark;

        transition: $transition-box-hover;


        // States
        &:hover {
            box-shadow: $default-box-shadow;
        }
    }

    .tag {
        position: absolute;
        top: 0;
        right: 0;
    }
}
