/*
    Ticket list inside ticket categories on the tickets landing page.
 */
.ticket-list {
    width: 100%;
    margin-bottom: rem(30);

    border-collapse: collapse;

    &-item {
        font-size: rem(16);

        background: $color-light;

        &:nth-child(odd) {
            background: $color-light-shade-2;
        }
    }

    &-item-name {
        width: 100%;
        padding-top: rem(13);
        padding-bottom: rem(13);
        padding-left: rem(10);

        vertical-align: baseline;
    }

    &-item-from { // Only displayed on desktop
        display: none;

        @media (--from-normal-screen-w) {
            display: block;

            padding-left: rem(12);

            font-size: rem(16);
            white-space: nowrap;
            vertical-align: baseline;
        }
    }

    &-item-price {
        padding-right: rem(18);
        padding-left: rem(15);

        text-align: right;
        vertical-align: middle;

        @media (--from-normal-screen-w) {
            text-align: left;
            vertical-align: baseline;
        }
    }

    &-item-price-from { // Only displayed on mobile
        font-size: rem(16);
        line-height: 1;
        white-space: nowrap;

        @media (--from-normal-screen-w) {
            display: none;
        }
    }

    &-item-price-value {
        font-weight: $weight-bold;
        line-height: 1;
        white-space: nowrap;
    }
}
