/*
    Loader
 */
.loader {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(60);
    height: rem(60);


    // Types
    &.block {
        width: 100%;
    }

    &.small {
        width: rem(30);
        height: rem(30);

        .loader-spinner {
            width: rem(20);
            height: rem(20);

            border: rem(3) solid $color-light;
            border-bottom-color: transparent;
            border-radius: 50%;
        }
    }


    // Elements
    &-spinner {
        display: block;
        width: rem(40);
        height: rem(40);

        border: rem(8) solid $color-primary;
        border-bottom-color: transparent;
        border-radius: 50%;

        content: '';
    }

    &-text {
        display: none;
    }
}
