/*
    Quote
 */
blockquote,
q {
    position: relative;

    width: rem(350);
    max-width: 100%;
    padding-left: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: rem(20);
    margin-left: 0;

    quotes: '“' '”' '‘' '’';


    // Elements
    &::before {
        display: block;
        height: rem(40);

        color: $color-primary;
        font-family: $font-tertiary;
        font-size: rem(40);
        line-height: 1;

        content: open-quote;

        @media (--from-medium-screen-w) {
            height: rem(50);

            font-size: rem(50);
        }
    }

    p {
        font-family: $font-secondary;
        font-size: rem(22);
        font-weight: $weight-semibold;
        line-height: 1.5;


        @media (--from-small-screen-w) {
            font-size: rem(24);
        }

        &::after {
            content: close-quote;
        }
    }
}


blockquote + h5,
q + h5,
cite {
    display: block;
    margin-top: 0;
    margin-bottom: rem(20);

    color: $color-dark-tint-2;

    font-size: rem(16);
    font-style: normal;
    line-height: 1.1;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
