/*
    Article View
 */
.article-view {

    // Specific content block alignment
    .text-block .constrain-content {
        margin-right: auto;
        margin-left: auto;
    }
}
