.tooltip {

    // Elements
    &-box {
        position: absolute;
        top: 110%;
        right: rem(-10);
        z-index: $layer-tooltip;

        max-width: rem(220);
        padding: rem(12);

        font-size: rem(14);

        pointer-events: none;
        background: $color-light;
        border: rem(1) solid $color-light-shade-2;
        border-radius: rem(5);
        box-shadow: 0 rem(5) rem(6) 0 rgba(0, 0, 0, 0.06);
        opacity: 0;

        transition: opacity 0.25s ease-in-out;

        @media (--from-ipad-screen-w) {
            right: rem(-82);
        }


        // States
        &.is-active {
            z-index: $layer-tooltip-active;

            opacity: 1;
        }


        // Elements
        &:after,
        &:before {
            position: absolute;
            top: rem(-16);
            right: rem(13);


            border-right: rem(16) solid transparent;
            border-bottom: rem(16) solid $color-light-shade-2;
            border-left: rem(16) solid transparent;

            @media (--from-ipad-screen-w) {
                right: auto;
                left: calc(50% - rem(16) / 2);
            }

            content: '';
        }

        &:after {
            top: rem(-15);
            z-index: $layer-tooltip-active;

            border-bottom: rem(16) solid $color-light;
        }
    }

    &-button {
        position: absolute;
        right: 0;
        bottom: 0;

        width: rem(40);
        height: rem(44);

        .icon {
            width: rem(20);
            height: rem(20);
        }
    }
}
