/*
    Rolling text Block
*/
.rolling-text-block {
    &.dual-items {
        display: flex;
        flex-direction: column;

        @media (--from-large-screen-w) {
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;
        }

        & > * {
            width: 100%;
            
            &:not(:last-child) {
                margin-bottom: rem(20);

                @media (--from-large-screen-w) {
                    margin-bottom: 0;
                }
            }

            @media (--from-large-screen-w) {
                width: calc(50% - rem(10));
            }
        }
    }

    .rolling-text-wrapper {
        box-shadow: $large-box-shadow;
        padding: rem(20);

        @media (--from-medium-screen-w) {
            padding: rem(30) rem(30) rem(20);
        }

        @media (--from-normal-screen-w) {
            padding: rem(40) rem(40) rem(20);
        }
    }
    
    .rolling-text-wrapper {
        background-color: $color-primary;
        color: $color-dark;

        h1 em,
        h2 em {
            color: $color-light;
        }

        ul li::before {
            border-color: $color-dark;
        }

        .pagination {
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-prev,
            .btn-next {
                color: $color-dark;
            }

            .btn-prev {
                .icon {
                    transform: rotate(90deg);
                }
            }

            .btn-next {
                .icon {
                    transform: rotate(-90deg);
                }
            }

            .dots {
                display: flex;
                align-items: center;
                justify-content: center;

                .dot {
                    display: inline-block;
                    width: rem(5);
                    height: rem(5);
                    border-radius: 50%;
                    background-color: $color-dark;
                    margin: 0 rem(5);
                    transition: width 0.2s ease-in;

                    &.is-active {
                        width: rem(15);
                        border-radius: rem(5);
                    }
                }
            }
        }
    }
}