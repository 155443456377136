/*
    Members portal dashboard
*/
.members-portal-dashboard {
    margin-bottom: rem(20);

    .text {
        color: $color-dark;

        &-semibold {
            font-weight: $weight-semibold;
        }

        &-dark {
            color: $color-dark;
        }

        &-light {
            color: $color-dark-tint-2;
        }

        &-small {
            font-size: rem(14);
        }
    }

    .heading-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (--from-small-screen-w) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: rem(20);
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        font-size: rem(34);
        margin-bottom: rem(20);
        
        @media (--from-small-screen-w) {
            margin-bottom: 0;
        }

        @media (--from-medium-screen-w) {
            flex-direction: row;
        }

        .name {
            display: inline-block;

            @media (--from-medium-screen-w) {
                margin-left: rem(10);
            }
        }
    }

    .annual-pass-selector {
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
            margin-bottom: 0;
        }

        .btn-prev,
        .btn-next {
            color: $color-primary;

            &:disabled {
                pointer-events: none;
                cursor: not-allowed;
                color: $color-light-shade-3;
            }
        }

        .icon.prev {
            transform: rotate(90deg);
        }

        .icon.next {
            transform: rotate(-90deg);
        }
    }

    .membership-details-card {
        padding-left: rem(30);
        padding-right: rem(30);
        box-shadow: $large-box-shadow;

        @media (--from-medium-screen-w) {
            padding-left: rem(40);
            padding-right: rem(40);
        }

        h2,
        p {
            margin-bottom: 0;
        }

        h2 {
            font-size: rem(32);
        }

        .membership-details-row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (--from-medium-screen-w) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            &:not(:last-child) {
                border-bottom: rem(1) solid $color-light-shade-2;
            }

            &:first-child {
                padding-bottom: rem(20);
            }

            &:last-child {
                padding-top: rem(20);
            }
        }

        .membership-details-colomn {
            @media (--from-medium-screen-w) {
                margin-right: rem(20);
            }

            &:last-child {
                margin-right: 0;
            }

            & > * {
                margin-bottom: rem(8);

                @media (--from-medium-screen-w) {
                    margin-bottom: 0;
                }
            }
        }

        .membership-status {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (--from-medium-screen-w) {
                justify-content: flex-end;
                margin-bottom: rem(10);
            }

            .status-circle {
                display: block;
                width: rem(20);
                height: rem(20);
                margin-right: rem(10);
                border-radius: 50%;
                background-color: $color-gold; // expiring / pending

                &.is-active {
                    background-color: $color-success;
                }

                &.is-expired {
                    background-color: $color-saturn;
                }
            }
        }

        .membership-expires span {
            display: inline-block;
            margin-left: rem(5);
        }

        .button-group {
            margin-top: rem(20);

            @media (--from-medium-screen-w) {
                margin-top: 0;
            }

            & > .button {
                padding: 0 rem(20);

                @media (--from-small-screen-w) {
                    padding: 0 rem(30);
                }
    
            }
        }

         // State
         &.is-expired {
            &::after {
                position: absolute;
                content: '';
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color-overlay-opac-2;
            }

            .membership-status-expires,
            .button-group {
                z-index: 1;
            }

            .membership-status,
            .membership-expires,
            .membership-expires .text-dark {
                color: $color-light;
            }
        }
    }
}