/*
    Horizontal Selector (Desktop)
*/
.horizontal-selector-desktop {
    background-color: $color-light;
    border-radius: 100px;

    @media (--to-small-screen-w) {
        display: none; // Hidden on mobile where the Horizontal Selector (Mobile) is used.
    }


    // Elements
    .blob {
        position: absolute;

        background-color: $color-primary;
        border-radius: 100px;

        transition: all ease-in-out 0.2s;
    }

    .label-box {
        position: relative;

        display: flex;
    }

    .label {
        display: inline-block;
        flex-grow: 1;
        flex-shrink: 0;
        height: rem(44);
        min-width: rem(80);
        padding-top: 0;
        padding-right: rem(17);
        padding-bottom: 0;
        padding-left: rem(17);

        font-family: $font-secondary;
        font-size: rem(16);
        line-height: rem(44);
        letter-spacing: 0.1em;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;

        cursor: pointer;
        background: none;
        border: none;
        border-radius: 100px;

        transition: color 0.2s;
        appearance: none;
        user-select: none;

        @media (--from-medium-screen-w) {
            min-width: rem(150);
            padding-right: rem(30);
            padding-left: rem(30);
        }


        // States
        &.is-active {
            color: $color-light;
        }
    }
}
