/*
    Keyboard Hint
 */
.keyboard-hint {
    display: inline-block;
    padding: 0.2em 0.3em;
    margin-right: 0.1em;
    margin-left: 0.1em;

    font-size: 0.8em;

    pointer-events: none;
    border: rem(2) solid $color-dark-tint-2;
    border-radius: rem(3);
}
