/*
    Radio item
 */
.radio {
    position: relative;

    display: block;
    height: rem(80);
    padding-right: rem(75);
    padding-left: rem(20);

    font-size: rem(18);

    cursor: pointer;

    @media (--from-small-screen-w) {
        padding-right: rem(100);
        padding-left: rem(30);
    }


    // States
    &.is-active {
        color: $color-light;

        background-color: $color-primary;


        .radio-circle:after {
            position: absolute;
            top: 50%;
            left: 50%;

            width: rem(17);
            height: rem(17);

            background-color: currentColor;
            border-radius: 50%;
            content: '';

            transform: translate(-50%, -50%);
        }
    }


    // Elements
    input {
        display: none;
    }

    &-inner {
        position: absolute;
        top: 50%;
        right: rem(75);
        left: rem(20);

        display: block;

        transform: translateY(-50%);

        @media (--from-small-screen-w) {
            right: rem(100);
            left: rem(30);
        }
    }

    .radio-circle {
        position: absolute;
        top: 50%;
        right: rem(20);

        width: rem(26);
        height: rem(26);

        border: rem(1) solid currentColor;
        border-radius: 50%;
        content: '';

        transform: translateY(-50%);

        @media (--from-small-screen-w) {
            right: rem(50);
        }
    }
}
