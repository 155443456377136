/*
    Encounters block
 */
.encounters-block {

    // Elements
    .section-header .title {
        margin-bottom:  0;

        @media (--from-small-screen-w) {
            margin-bottom: rem(10);
        }


        // Elements
        &::after {
            content: none;
        }
    }

    .encounters {
        position: relative;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        min-height: rem(300);

        margin-right: rem(-$block-spacing-half);
        margin-left: rem(-$block-spacing-half);
    }

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    .error-message {
        flex-basis: 100%;

        text-align: center;
    }
}
