/*
    Header
 */
$header-scrolling-height: 80;
$header-scrolling-height-with-secondary-nav: 100;

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $layer-header;

    width: 100%;

    box-shadow: 0 10px 30px $color-dark-shade-1-opac-2;


    // States
    &.is-scrolling {

        // Elements
        .navigation {
            @media (--from-normal-screen-w) {
                height: rem($header-scrolling-height);

                .feature-zoo-secondary-nav & {
                    height: rem($header-scrolling-height-with-secondary-nav);
                }
            }
        }

        .logo {
            @media (--from-large-screen-w) {
                width: rem(260);
                max-height: rem($header-scrolling-height);

                background-color: $color-light;

                .feature-zoo-secondary-nav & {
                    height: rem($header-scrolling-height-with-secondary-nav);
                }
            }
        }

        .header-actions {
            @media (--from-normal-screen-w) {
                height: rem($header-scrolling-height);

                .feature-zoo-secondary-nav & {
                    height: rem($header-scrolling-height-with-secondary-nav);
                    padding-top: rem(20);
                }
            }
        }
    }


    // Elements
    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: rem(20);

        background-color: $color-light;

        @media (--to-small-screen-w) {
            flex-grow: 1;
            padding-left: 0;
        }

        @media (--from-normal-screen-w) {
            height: rem(100);

            .feature-zoo-secondary-nav & {
                height: rem(115);
                padding-top: rem(26);
            }
        }

        @media (--from-large-screen-w) {
            padding-left: rem(45);
        }


        // Elements
        &–spacer {
            display: block;
            flex-grow: 1;
            height: rem(1);

            @media (--from-small-screen-w) {
                display: none; // Hide for bigger resolutions
            }
        }

        a,
        button {
            flex-shrink: 0;

            &:not(:first-child) {
                @media (--to-small-screen-w) {
                    margin-left: rem(10);
                }
            }
        }
    }

    .header-search-toggle {
        display: block;
        align-items: center;
        justify-content: center;
        width: rem(40);
        height: rem(40);

        @media (--from-small-screen-w) and (--to-normal-screen-w) {
            margin-left: rem(20);
        }

        // Elements
        .icon {
            width: rem(30);
            height: rem(30);
        }
    }

    .header-cta {
        margin-left: auto;

        @media (--to-small-screen-w) {
            display: none;
        }

        @media (--from-normal-screen-w) {
            display: none;
        }

        a.button {
            margin-right: rem(10);

            text-decoration: none;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .inner {
        position: relative;

        display: flex;
        align-items: center;
        width: 100%;

        background-color: $color-light;

        @media (--to-tiny-screen-w) {
            padding-right: rem(15);
            padding-left: rem(10); // Reduce to improve left alignment of icon
        }

        @media (--to-small-screen-w) {
            padding-right: rem(20);
            padding-left: rem(15); // Reduce to improve left alignment of icon
        }

        @media (--to-normal-screen-w) {
            justify-content: space-between;
            height: rem(60);

            background-color: $color-light;
        }


        // Elements
        &::before,
        &::after {
            position: absolute;
            top: 0;

            width: 100%;
            height: 100%;

            background-color: $color-light;

            @media (--from-normal-screen-w) {
                content: '';
            }
        }

        &::before {
            left: -100%;

            margin-left: rem(31); // Overlap 1px for IE11
        }

        &::after {
            left: 100%;

            margin-left: rem(-30);
        }
    }

    .logo {
        position: relative;

        display: flex;
        flex-grow: 0;
        flex-shrink: 1;
        width: rem(100);;
        max-height: rem(40);
        overflow: hidden;

        background-color: $color-light;

        @media (--to-small-screen-w) {
            position: absolute;
            left: 50%;

            transform: translateX(-50%);
        }

        @media (--from-normal-screen-w) {
            max-height: unset;
        }

        @media (--from-large-screen-w) {
            width: rem(300);

            background-color: $color-light;

            transition: background-color 0.2s ease-in-out;
            
            .feature-zoo-secondary-nav & {
                height: rem(115);
            }
        }

        .img {
            &.desktop {
                display: none;

                @media (--from-large-screen-w) {
                    display: block;
                }
            }

            &.mobile {
                display: block;

                @media (--from-large-screen-w) {
                    display: none;
                }
            }
        }


        // Elements
        &-text {
            position: absolute;
            top: 100%;
            left: 100%;

            display: block;
        }
    }

    .close-mouse-capture {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $layer-close-mouse-capture;

        display: block;
        width: 100%;
        height: 100%;

        background-color: transparent;
        border: none;
        opacity: 0;

        appearance: none;

        @media (--to-normal-screen-w) {
            // This is a desktop only feature
            display: none;
        }
    }

    .close {
        color: $color-dark;
    }

    &-notification-toggle {
        position: relative;

        @media (--from-small-screen-w) {
            margin-right: rem(4);
            margin-left: rem(20);
        }

        &.is-active {
            &:before {
                opacity: 0;
            }
        }

        &:before {
            position: absolute;
            top: rem(10);
            right: rem(7);

            display: block;
            width: rem(8);
            height: rem(8);

            background-color: $color-primary;
            border-radius: 50%;


            @media (--from-small-screen-w) {
                top: rem(8);

                width: rem(12);
                height: rem(12);
            }

            content: '';
            opacity: 1;

            transition: opacity 0.15s ease-in-out;
        }
    }
}
