/*
    Modal
*/
.modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $layer-modal-active;

    width: 100%;
    height: 100%;
    overflow: scroll;

    background: color($color-light-shade-3 alpha(70%));

    opacity: 1;

    @media(--from-small-screen-w) {
        display: flex;
        align-items: center;
    }


    // States
    &.hidden {
        z-index: $layer-modal;

        opacity: 0;
    }


    // Elements
    &-title {
        margin-bottom: rem(40);
    }

    &-content {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: 85%;
        min-height: rem(250);

        padding: rem(60) rem(60) rem(30) rem(60);

        background: $color-light;

        @media(--to-small-screen-w) {
            width: 100%;
            max-width: 100%;
            min-height: 100%;
            padding: rem(10);
        }
    }

    &-close-button {
        position: absolute;
        top: rem(25);
        right: rem(25);

        .icon {
            width: rem(30);
            height: rem(30);
        }
    }
}
