/*
    Animation
 */

// Fade Up
@keyframes animate-fade-up {
    0% {
        opacity: 0;

        transform: translateY(rem(40));
    }

    100% {
        opacity: 1;

        transform: translateX(0);
    }
}

.animate-fade-up {
    animation: animate-fade-up 0.1s linear forwards;
}


// Spin
@keyframes animation-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animate-spin {
    animation: animation-spin 0.6s linear infinite;
}

// Dropdown in animation
@keyframes animate-fade-down {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}