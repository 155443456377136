/*
    Ticket type selector
 */
.ticket-type-selector {
    margin-right: rem(-20);
    margin-bottom: rem(-20);
    margin-left: rem(-20);

    @media (--from-small-screen-w) {
        margin-right: rem(-30);
        margin-left: rem(-30);
    }

    @media (--from-normal-screen-w) {
        margin-bottom: rem(-30);
    }
}
