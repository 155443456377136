/*
    Notification Banner
*/
.notification-banner {
    position: relative;
    z-index: 400;

    width: 100%;
    max-height: rem(2000); // Used for animating open/close
    overflow: hidden;

    background-color: $color-alert;

    transition: max-height 0.01s ease-in-out;


    // States
    &.is-hidden {
        max-height: 0;
    }
}
