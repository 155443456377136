/*
    News List
 */
.news-list {

    // States
    &.is-empty {
        // Elements
        .news-list-inner {
            min-height: rem(600);

            @media (--from-medium-screen-w) {
                min-height: rem(800);
            }

            @media (--from-normal-screen-w) {
                min-height: 100vh;
            }
        }
    }


    // Elements
    &-actions {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        // Hide when empty to remove margin
        &:not(:empty) {
            min-height: rem(60);
        }
    }

    .tile-grid {
        margin-bottom: rem(30);
    }

    .article-tile {
        animation-duration: 0.1s;
        animation-name: animate-fade-up;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
}
