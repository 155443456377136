/*
    Select
 */
.select {
    position: relative;

    width: 100%;
    padding-top: 0;
    padding-right: rem(8);
    padding-bottom: 0;
    padding-left: rem(8);

    font-size: rem(18);
    line-height: rem(42);

    background-color: $color-light;
    border: rem(1) solid $color-light-shade-3;
    border-radius: rem(3);


    // States
    &.is-invalid {
        border: rem(1) solid $color-primary;
    }


    // Elements
    .icon {
        position: absolute;
        top: 53%;
        right: 0;

        width: 2em;
        height: 2em;

        color: $color-primary;

        transform: translateY(-50%);
    }

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        color: inherit;
        font-size: inherit;

        background-color: transparent;
        border: 0;
        opacity: 0;

        appearance: none;

        @media (-webkit-min-device-pixel-ratio: 0) {
            font-size: 16px; // IOS specific: Min 16px font-size to prevent zoom
        }


        // Types
        &::-ms-expand {
            display: none; // or visibility: hidden; to keep it's space/hitbox
        }


        // Elements
        option {
            font-size: rem(18);

            background-color: $color-light;
        }
    }
}
