/*
    Scalable Object
 */
.scalable-object {
    position: relative;

    padding-bottom: calc(100% / 1930 * 1300);
    margin-bottom: rem(20);
    overflow: hidden;

    background-color: $color-dark-tint-1;
    // Custom CSS grid background
    background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent);
    background-position: center;
    background-size: rem(60) rem(60);


    // States
    &.is-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        z-index: $layer-fullscreen-scalable-object;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-bottom: 0;

        .scalable-object-inner {
            height: inherit;
        }
    }


    // Elements
    &-inner {
        position: absolute;

        width: 100%;
        height: 100%;
        overflow: hidden;

        cursor: all-scroll;

        // Touch specific styling
        touchAction: none; // Not supported in Safari :(
        msTouchAction: none;
        WebkitUserSelect: none;
        MozUserSelect: none;
        msUserSelect: none;
    }

    &-mask > * {
        box-shadow: 0 rem(10) rem(30) $color-dark-shade-1-opac-1;
    }

    &-actions {
        @media (--from-medium-screen-w) {
            position: absolute;
            top: 0;
            right: 0;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: rem(10);
            padding-bottom: 0;
        }
    }

    &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(8);

        color: $color-light;

        background-color: $color-dark-shade-1-opac-1;
        border-radius: rem(3);

        @media (--from-medium-screen-w) {
            margin-bottom: rem(10);
        }


        // States
        &:hover {
            background-color: $color-dark-shade-1-opac-2;
        }

        &:disabled {
            background-color: $color-dark-shade-1-opac-3;
        }


        // Types
        &.fullscreen {
            @media (--to-medium-screen-w) {
                position: absolute;
                top: rem(10);
                right: rem(10);
            }

            // States
            &.is-active .icon.in-active {
                display: none;
            }

            &:not(.is-active) .icon.active {
                display: none;
            }
        }

        &.zoom-in {
            @media (--to-medium-screen-w) {
                display: none; // Hide zoom controls
            }

            @media (--from-medium-screen-w) {
                margin-bottom: 0;

                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.zoom-out {
            @media (--to-medium-screen-w) {
                display: none; // Hide zoom controls
            }

            @media (--from-medium-screen-w) {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.reset {
            @media (--to-medium-screen-w) {
                position: absolute;
                right: rem(10);
                bottom: rem(10);
            }
        }


        // Elements
        .icon {
            width: rem(24);
            height: rem(24);
        }
    }

    &-image {
        max-width: none;

        pointer-events: none; // Avoid dragging the <img /> while-interacting
    }

    &-meta {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-top: rem(15);
        margin-bottom: 0;

        pointer-events: none; // Prevent interaction with this layer
        background-color: color($color-dark-shade-1 alpha(60%));


        p {
            margin-bottom: 0;

            color: $color-light;
            line-height: 1;
            text-align: center;

            @media (--from-normal-screen-w) {
                font-size: rem(26);
            }
        }
    }
}
