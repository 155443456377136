/*
    Heading
 */
h1,
h2,
h3,
h4,
h5,
h6,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    margin-top: 0;
    margin-bottom: rem(10);

    line-height: 1.2;
}

h1,
h2,
.heading-1,
.heading-2 {
    font-family: $font-primary;
    font-weight: $weight-semibold;
}

h3,
h4,
.heading-3,
.heading-4 {
    font-family: $font-secondary;
    font-weight: $weight-semibold;
}

h5,
h6,
.heading-5,
.heading-6 {
    font-family: $font-secondary;
    font-weight: $weight-bold;
}

h1,
.heading-1 {
    font-size: rem(40);


    @media (--from-medium-screen-w) {
        margin-bottom: rem(35);

        font-size: rem(52);
    }


    // Sizes
    &.large {
        font-size: rem(50);

        @media (--from-small-screen-w) {
            font-size: rem(70);
        }

        @media (--from-medium-screen-w) {
            font-size: rem(85);
        }

        @media (--from-normal-screen-w) {
            font-size: rem(110);
        }
    }
}

h2,
.heading-2 {
    font-family: $font-primary;
    font-size: rem(36);

    @media (--from-medium-screen-w) {
        margin-bottom: rem(30);
    }
}

h3,
.heading-3 {
    font-size: rem(22);

    @media (--from-small-screen-w) {
        font-size: rem(24);
    }
}

h4,
.heading-4 {
    font-size: rem(22);
}

h5,
.heading-5 {
    color: $color-light-shade-5;
    font-size: rem(16);
    font-weight: $weight-semibold;
    text-transform: uppercase;
}

h6,
.heading-6 {
    margin-bottom: rem(20);

    font-size: rem(16);
    font-style: italic;
    font-weight: $weight-normal;
}
