/*
    Stackla Widget Block
 */
.stackla-widget-block {
    display: none; // Hides the stackla (it can still load the iframe)
    margin-bottom: rem(40);


    // States
    &.is-active {
        display: block;
    }

    @media (--from-medium-screen-w) {
        margin-bottom: rem(80);
    }
}
