/*
    Banner
 */
.banner {
    position: relative;

    max-width: 100%;

    margin-right: auto;
    margin-bottom: rem($block-spacing);
    margin-left: auto;


    // Types
    &:only-child:not(.hero-carousel) {
        margin-bottom: rem(60);

        @media (--from-normal-screen-w) {
            margin-bottom: rem(80);
        }
    }

    &.no-image {
        &::before {
            display: block;
            width: 100%;
            height: rem($section-overlap-small);
            margin-bottom: rem(100);

            content: '';

            @media (--from-small-screen-w) {
                height: rem($section-overlap);
            }

            @media (--from-normal-screen-w) {
                height: rem($section-overlap-large);
            }
        }
    }

    &.has-image {
        .introduction {
            margin-top: rem(-$section-overlap-small);

            @media (--from-small-screen-w) {
                margin-top: rem(-$section-overlap);
            }

            @media (--from-normal-screen-w) {
                margin-top: rem(-$section-overlap-large);
            }
        }
    }


    // Elements
    .image {
        position: relative;

        max-width: 100%;
        max-height: rem(620);
        overflow: hidden;

        background-color: $color-dark;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        // Types
        &.is-contained {
            background-color: transparent;
            background-position: center 55%;
            background-size: 70% auto;

            @media (--from-large-screen-w) {
                background-position: center 80%;
                background-size: 60% auto;
            }
        }


        // Elements
        &::before {
            display: block;
            padding-top: calc(2 / 3 * 100%);

            content: '';
        }

        img {
            display: none;
        }
    }

    .introduction {
        z-index: $layer-banner;

        display: flex;
        justify-content: center;
        width: 100%;

        box-shadow: $large-box-shadow;
    }

    .heading-group {
        flex-basis: rem($constrain-width-content);
        padding-top: rem(40);
        padding-right: rem(20);
        padding-left: rem(20);
        margin-bottom: rem(30);

        text-align: center;

        @media (--to-medium-screen-w) {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        @media (--from-medium-screen-w) {
            padding-top: rem(60);
            margin-bottom: rem(40);
        }


        // Elements
        > *  {
            order: 2;
        }

        .title {
            margin-bottom: rem(10);
        }

        .tag {
            order: 1;
        }
    }

    .tag {
        @media (--to-medium-screen-w) {
            margin-top: rem(-$section-overlap-small);
            margin-bottom: rem(30);
        }

        @media (--from-medium-screen-w) {
            position: absolute;
            top: 0;
            left: 50%;

            max-width: 100%;

            transform: translateX(-50%);
        }
    }

    .actions {
        padding-top: rem(20);
    }

    .subtitle > * {
        padding-right: rem(5);
        padding-left: rem(5);
    }

    .avatar {
        display: inline-block;
        width: rem(55);
        height: rem(55);
        margin-top: rem(10);

        background-color: $color-dark-tint-1;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
    }
}
