/*
    File Download
 */
.file-download {
    position: relative;

    @media (--from-small-screen-w) {
        display: flex;
        justify-content: space-between;
    }


    // Elements
    .details {
        position: relative;

        flex-grow: 1;
        padding-left: rem(65);


        // Elements
        .icon {
            position: absolute;
            top: 0;
            left: 0;

            width: rem(50);
            height: rem(50);
        }
    }

    .title {
        margin-bottom: 0;
    }

    .button {
        margin-bottom: rem(10);

        @media (--from-small-screen-w) {
            margin-top: rem(5);
        }
    }
}
