/*
    Horizontal Selector (Mobile)
*/
.horizontal-selector-mobile {
    position: relative;

    display: block;
    padding-right: 2em;
    padding-left: 2em;

    font-size: rem(18);
    text-align: center;

    background-color: $color-light;
    border: 0;
    border-radius: 3px;
    box-shadow: inset 1px 1px 1px 0 rgba(180, 180, 180, 0.5);

    @media (--from-small-screen-w) {
        display: none; // Hidden on desktop where the Horizontal Selector (Desktop) is used.
    }


    // Elements
    .icon {
        position: absolute;
        top: 53%;
        right: 0;

        width: 2em;
        height: 2em;

        color: $color-primary;

        transform: translateY(-50%);
    }

    .label {
        display: block;
        height: 2.17em;

        line-height: 2.17em;
        white-space: nowrap;
    }

    .select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        color: transparent;
        font-size: 0;

        background-color: transparent;
        border: 0;
        opacity: 0.5;

        appearance: none;

        @media (-webkit-min-device-pixel-ratio: 0) {
            font-size: 16px; // IOS specific: Min 16px font-size to prevent zoom
        }


        // Types
        &::-ms-expand {
            display: none; // or visibility: hidden; to keep it's space/hitbox
        }


        // Elements
        option {
            font-size: rem(18);

            background-color: $color-light;
        }
    }
}
