/*
    Content Blocks
 */
.content-blocks {

    // Elements
    .content-media-block:nth-child(2n+1) {

        // Elements
        .media {
            @media (--from-normal-screen-w) {
                order: 2;
            }
        }

        .content {
            @media (--from-normal-screen-w) {
                order: 1;
            }
        }
    }

    .pricing-block ~ .button-block,
    .text-block ~ .button-block {
        // margin-top: rem(-$section-overlap-small);


        // Elements
        .inner {
            padding-top: 0;
        }
    }

    .pricing-block ~ .file-download-block,
    .text-block ~ .file-download-block,
    .text-block ~ .audio-block,
    .file-download-block ~ .file-download-block {
        margin-top: rem(-$section-overlap-small);


        // Elements
        .file-download::before {
            position: absolute;
            top: rem(-22);
            left: 0;

            width: 100%;

            border-top: rem(2) solid $color-light-shade-2;

            content: '';

            @media (--from-normal-screen-w) {
                top: rem(-40);
            }
        }
    }

    .text-block ~ .audio-block .inner {
        padding-top: 0;
    }


    // Remove padding for all section headers in the section that directly follow the carousel
    .carousel-block + * .section-header .title {
        padding-top: 0;
        margin-top: 0;
    }

    .encounters-block:not(:last-child) {
        padding-bottom: rem(40);
        margin-bottom: rem($block-spacing);

        @media (--from-normal-screen-w) {
            padding-bottom: rem(60);
        }
    }
}
