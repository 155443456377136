/*
    Members portal account
*/
.members-portal-account {
    .account-settings-form {
        max-width: rem(800);
        margin-bottom: rem(20);

        .button {
            display: block;

            &:not(:last-child) {
                margin-bottom: rem(20);
            }
        }
    }

    .modal-content {
        padding-left: rem(10);
        padding-right: rem(10);

        @media (--from-small-screen-w) {
            padding-left: rem(60);
            padding-right: rem(60);
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(50);
            height: rem(50);
            margin: 0 auto;
            color: $color-light;
            border-radius: 50%;
            margin-bottom: rem(20);

            &.success {
                background-color: $color-success;
            }

            &.error {
                background-color: $color-error;
            }
        }
    }
}