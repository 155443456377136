/*
    Copyrights
 */
.copyrights {
    padding-bottom: rem(20);

    @media (--to-small-screen-w) {
        order: 3;
    }

    .inner {
        display: flex;

        @media (--to-small-screen-w) {
            align-items: center;
            flex-direction: column-reverse;
        }

        @media (--from-small-screen-w) {
            align-items: flex-end;
            justify-content: space-between;
            padding-top: rem(25);
        }
    }


    // Elements
    .fine-text,
    .link {
        color: $color-dark-tint-1;
    }

    .fine-text {
        display: block;
        text-align: center;

        @media (--from-small-screen-w) {
            display: inline-block;
        }

        // Elements
        .link {
            @media (--from-medium-screen-w) {
                text-decoration: none;
            }


            // States
            &:hover {
                text-decoration: underline;
            }
        }

        & + .fine-text::before {
            @media (--from-small-screen-w) {
                position: relative;
                top: rem(2);
    
                display: inline-block;
                width: rem(1);
                height: rem(12);
                margin-top: rem(2);
                margin-right: rem(7);
                margin-left: rem(7);
    
                background-color: currentColor;
    
                content: '';
            }
        }
    }

    .logo {
        height: auto;
        max-width: rem(270);
        max-height: rem(100);

        @media (--to-small-screen-w) {
            margin-top: rem(-5);
            margin-bottom: rem(15);
        }
    }
}
