/*
    Tile Grid Sidebar
*/
.tile-grid-sidebar {

    // Elements
    &-inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        background-color: $color-light;
        box-shadow: $default-box-shadow;
    }

    &-header {
        padding-top: rem(20);
        padding-right: rem(30);
        padding-bottom: rem(10);
        padding-left: rem(26);

        @media (--to-normal-screen-w) {
            text-align: center;
        }

        @media (--from-normal-screen-w) {
            border-left: rem(3) solid $color-primary;
        }
    }
}
