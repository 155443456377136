/*
    Section Header
 */
.section-header {
    margin-bottom: rem($block-spacing);


    // Elements
    .inner {
        display: flex;
        margin-right: rem(-$block-spacing-half);
        margin-left: rem(-$block-spacing-half);
    }

    .title-wrapper {
        width: 100%;
        padding-right: rem($block-spacing-half);
        padding-left: rem($block-spacing-half);

        text-align: center;

        @media (--from-medium-screen-w) {
            margin-bottom: rem(10);
        }
    }

    .title {
        position: relative;

        display: block;
        padding-bottom: rem(20);
        margin-top: rem(20);
        margin-bottom: rem(20);

        color: $color-dark;

        @media (--from-medium-screen-w) {
            padding-top: rem(20);
            padding-bottom: rem(20);
        }


        // Elements
        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;

            width: rem(60);
            height: rem(3);
            margin-right: auto;
            margin-left: auto;

            background-color: $color-light-shade-3;

            content: '';

            @media (--from-medium-screen-w) {
                width: rem(100);
                height: rem(4);
            }
        }
    }

    .subtitle {
        margin-bottom: rem(20);

        @media (--from-medium-screen-w) {
            font-size: rem(18);
        }
    }
}
