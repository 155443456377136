/*
    Stackla

    Description:
    This core overrides for the Stackla classes. The pre-requested stylesheet used for the widget is
    here: https://assetscdn.stackla.com/media/components/stackla-uikit/dist/uikit-widget.css?1470274271.
 */
.stacklapopup-wrap {

    // Elements
    .stacklapopup-content-wrap,
    .stacklapopup-caption {
        color: $color-dark;
        font-family: $font-secondary;
    }

    .stacklapopup-user-name,
    .stacklapopup-user-handle {
        color: $color-dark;
    }

    .stacklapopup-interaction-link {
        text-decoration: none;
    }

    .stacklapopup-arrow {
        color: $color-light;

        background-color: $color-primary;

        opacity: 1;
    }
}

.stackla-widget-mask {
    position: fixed !important; // Override inline style to prevent huge overflow in the body
}
