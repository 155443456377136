/*
    Aside
 */
.aside {
    padding-top: rem(10);

    @media (--from-normal-screen-w) {
        flex-basis: calc(100% / 3);
        flex-shrink: 1;
        padding-right: rem(30);
        padding-left: rem($block-spacing-half);
    }


    // Elements
    li {
        @media (--from-normal-screen-w) {
            font-size: rem(16);
        }
    }

    // Img
    img {
        height: auto !important; // Override ckeditor image inline style height
    }
}
