/*
    Introduction Block
 */
.introduction-block {
    margin-bottom: rem($block-spacing);


    // Elements
    .inner {
        padding-top: rem(25);
        padding-right: rem(20);
        padding-bottom: rem(5);
        padding-left: rem(20);

        color: $color-light;

        background-color: $color-dark-opac-2;
        background-position: center bottom;
        background-repeat: no-repeat;
        box-shadow: $default-box-shadow;

        @media (--from-tiny-screen-w) {
            background-position: right bottom;
        }
        
        @media (--from-small-screen-w) {
            padding-right: rem(30);
            padding-left: rem(30);
        }

        @media (--from-medium-screen-w) {
            padding-top: rem(40);
            padding-bottom: rem(20);
        }

        @media (--from-normal-screen-w) {
            padding-top: rem(75);
            padding-right: rem(70);
            padding-bottom: rem(45);
            padding-left: rem(80);
        }
    }

    h1 {
        @media (--to-medium-screen-w) {
            margin-bottom: rem(25);
        }


        // Elements
        em {
            color: $color-primary;
        }
    }
}
